import { useState } from "react";

import { Book, Calf } from "../../../models";
import { OrgData } from "../../../models/organization";
import { useBookSearch } from "./BookToolbar/BookSearchProvider";
import useBooksService from "../../../services/useBooksService";
import useOrganizationsService from "../../../services/useOrganizationsService";
import { useIonViewWillEnter } from "@ionic/react";

export function useBookDetail(bookId: string) {
  const { getOrganization } = useOrganizationsService();
  const { getBook, getBookCalvesSub } = useBooksService();

  const [book, setBook] = useState<Book>();
  const [calves, setCalves] = useState<Calf[]>([]);
  const [organizationData, setOrganizationData] = useState<OrgData>();

  const { sortBy, searchValue, sortDirection } = useBookSearch();

  useIonViewWillEnter(() => {
    async function fetchBook() {
      const book = await getBook(bookId);
      setBook(book);
    }

    async function fetchCalves() {
      getBookCalvesSub(bookId, setCalves);
    }

    async function fetchOrganization() {
      const organizationData = await getOrganization();
      setOrganizationData(organizationData);
    }

    fetchBook();
    fetchCalves();
    fetchOrganization();
  }, [bookId]);

  const filterCalfs = (calves: Calf[]) => {
    if (searchValue && searchValue.length > 0) {
      return calves.filter((calve) => {
        return (
          calve.gender?.toLowerCase().includes(searchValue.toLowerCase()) ||
          calve.calfName?.toLowerCase().includes(searchValue.toLowerCase()) ||
          calve.comment?.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
    }

    return calves;
  };

  const sortCalfs = (calfA: Calf, calfB: Calf) => {
    const sortPriority = sortDirection === "asc" ? -1 : 1;
    const sortPriorityCalfA = sortDirection === "asc" ? 1 : -1;
    const sortPriorityCalfB = sortDirection === "asc" ? -1 : 1;

    if (!calfA[sortBy]) return 0;

    if (sortBy) {
      if (calfA[sortBy] === calfB[sortBy]) return 0;

      return (calfA[sortBy] || "") > (calfB[sortBy] || "")
        ? sortPriorityCalfA
        : sortPriorityCalfB;
    }

    return sortPriority;
  };

  return {
    book,
    organizationData,
    calves: filterCalfs(calves).sort(sortCalfs),
  };
}
