import { FormProvider } from "react-hook-form";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

import "./styles.css";

import TwinForm from "../TwinForm";
import { IonCard } from "@ionic/react";
import TwinNavigation from "./TwinNavigation";

interface SliderTwinsFormProps {
  colors: string[];
  twinOneFormApi: any;
  twinTwoFormApi: any;
  insertNewColor: (color: string) => void;
}

export default function SliderTwinsForm({
  colors,
  twinOneFormApi,
  twinTwoFormApi,
  insertNewColor,
}: SliderTwinsFormProps): React.ReactElement {
  return (
    <IonCard className={"TwinFormCard"}>
      <Swiper>
        <SwiperSlide>
          <div className="TwinForm">
            <TwinNavigation label={"Twin 1"} />

            <FormProvider {...twinOneFormApi}>
              <TwinForm colors={colors} insertNewColor={insertNewColor} />
            </FormProvider>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="TwinForm">
            <TwinNavigation label={"Twin 2"} />
            <FormProvider {...twinTwoFormApi}>
              <TwinForm colors={colors} insertNewColor={insertNewColor} />
            </FormProvider>
          </div>
        </SwiperSlide>
      </Swiper>
    </IonCard>
  );
}
