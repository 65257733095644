import { useEffect, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IonButton, IonButtons, IonContent, IonModal } from "@ionic/react";

import Form from "../BreedingForm/Form";
import { Breeding } from "../../../../models";
import Header from "../../../../components/Header";

interface BreedAttemptModalProps {
  isOpen: boolean;
  close: () => void;
  breedAttempt?: Breeding;
  onFormSubmit: (data: Breeding) => void;
}
export default function BreedAttemptModal({
  close,
  isOpen,
  breedAttempt,
  onFormSubmit,
}: BreedAttemptModalProps): React.ReactElement {
  const modal = useRef<HTMLIonModalElement>(null);
  const formApi = useForm<Breeding>({
    defaultValues: {
      aiTech: breedAttempt?.aiTech || "",
      sireId: breedAttempt?.sireId || "",
      attemptDate: breedAttempt?.attemptDate || undefined,
    },
  });

  const { reset, handleSubmit } = formApi;

  useEffect(() => {
    if (breedAttempt) {
      reset(breedAttempt);
    } else {
      reset({
        aiTech: "",
        sireId: "",
        attemptDate: undefined,
      });
    }
  }, [reset, breedAttempt]);

  const onSubmit = handleSubmit((data) => {
    onFormSubmit(data);
    reset({
      aiTech: "",
      sireId: "",
      attemptDate: undefined,
    });
  });

  return (
    <IonModal isOpen={isOpen} ref={modal} onWillDismiss={(e) => close()}>
      <Header
        title={"Breed Attempt"}
        rightComponent={
          <IonButtons>
            <IonButton
              size={"small"}
              fill={"clear"}
              className="ion-text-uppercase ion-no-margin ion-no-padding"
              onClick={() => {
                modal.current?.dismiss();
              }}
            >
              Close
            </IonButton>
            <IonButton
              size={"small"}
              fill={"clear"}
              className="ion-text-uppercase ion-no-margin ion-no-padding"
              onClick={() => {
                onSubmit();
                modal.current?.dismiss();
              }}
            >
              Save
            </IonButton>
          </IonButtons>
        }
      />
      <IonContent>
        <FormProvider {...formApi}>
          <Form />
        </FormProvider>
      </IonContent>
    </IonModal>
  );
}
