import { v4 as uuidv4 } from "uuid";
import { useEffect, useMemo, useState } from "react";
import { IonPage, useIonRouter } from "@ionic/react";

import BooksList from "./BooksList";
import BooksSelection from "./BooksSelection";
import { Book, Vaccine } from "../../../models";
import ExcludeCalvesModal from "./ExcludeCalvesModal";
import VaccineForm from "../../../components/Vaccines/VaccineForm";
import useBooksService from "../../../services/useBooksService";
import useVaccinesService from "../../../services/useVaccinesService";

export default function AddBooksBatchVaccine(): React.ReactElement {
  const router = useIonRouter();
  const { getBooksSub } = useBooksService();
  const [books, setBooks] = useState<Book[]>([]);
  const [excludedCalves, setExcludedCalves] = useState(
    new Map<string, string[]>()
  );
  const { addBatchVaccine, addBatchToCattle } = useVaccinesService();
  const [currentBook, setCurrentBook] = useState<string | null>(null);

  useEffect(() => {
    getBooksSub(setBooks);
  }, [getBooksSub]);

  const onBookSelected = (booksId: any) => {
    console.log(booksId);
    const keys = Array.from(excludedCalves.keys());

    keys.forEach((key) => {
      if (!booksId.includes(key)) {
        excludedCalves.delete(key);
      }
    });

    booksId.forEach((bookId: string) => {
      if (!excludedCalves.has(bookId)) {
        excludedCalves.set(bookId, []);
      }
    });

    setExcludedCalves(new Map(excludedCalves));
  };

  const onBookUnselected = (bookId: string) => {
    excludedCalves.delete(bookId);
    setExcludedCalves(new Map(excludedCalves));
  };

  const onCalfClicked = (calfId: string, bookId: string) => {
    const currentExclude = excludedCalves.get(bookId) || [];
    if (currentExclude.includes(calfId)) {
      return setExcludedCalves((prev) => {
        prev.set(
          bookId,
          currentExclude.filter((currCalfId) => currCalfId !== calfId)
        );
        return new Map(prev);
      });
    }

    setExcludedCalves((prev) => {
      prev.set(bookId, [...currentExclude, calfId]);
      return new Map(prev);
    });
  };

  const onAddBooksBatchVaccine = async (data: Vaccine) => {
    try {
      const batchId = uuidv4();
      const excludedCattle: string[] = [];
      excludedCalves.delete("");
      const booksIds: string[] = Array.from(excludedCalves.keys());
      excludedCalves.forEach((excluded) => excludedCattle.push(...excluded));
      addBatchVaccine(batchId, {
        ...data,
        booksIds,
        isBookBatch: true,
      });
      addBatchToCattle(batchId, excludedCattle, "calves", "calfId");

      router.push(`/books`);
    } catch (err) {
      alert(err);
    }
  };

  const selectedBooks = useMemo(() => {
    return Array.from(excludedCalves.keys());
  }, [excludedCalves]);

  return (
    <IonPage>
      <VaccineForm
        title="Add Batch Vaccine"
        backLink="/books"
        onFormSubmit={onAddBooksBatchVaccine}
      />

      <div style={{ flex: 1 }}>
        <BooksSelection
          books={books}
          onBookSelected={onBookSelected}
          selectedBooks={Array.from(excludedCalves.keys())}
        />
        <BooksList
          books={books}
          selectedBooks={selectedBooks}
          onBookUnselected={onBookUnselected}
          onOpenExcludedCalves={(bookId) => setCurrentBook(bookId)}
        />
        {currentBook && (
          <ExcludeCalvesModal
            currentBook={currentBook}
            onCalfClicked={onCalfClicked}
            close={() => setCurrentBook(null)}
            excludedCalves={excludedCalves.get(currentBook) || []}
          />
        )}
      </div>
    </IonPage>
  );
}
