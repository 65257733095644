import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { IonPage, useIonRouter, useIonToast } from "@ionic/react";

import CowForm from "../CowForm";
import { Cow } from "../../../models";
import useCowsService from "../../../services/useCowsService";

type RouteParams = { cowId: string };

export default function EditCow(): React.ReactElement {
  const router = useIonRouter();
  const [present] = useIonToast();
  const [cow, setCow] = useState<Cow>();
  const { cowId } = useParams<RouteParams>();
  const { getCow, updateCow } = useCowsService();

  useEffect(() => {
    async function fetchCow() {
      const cow = await getCow(cowId);
      setCow(cow);
    }
    fetchCow();
  }, [cowId, getCow]);

  const onEditCow = async (data: Cow) => {
    updateCow(cowId, data)
      .then(() => {
        router.push(`/cows`);
      })
      .catch((err) => {
        present({
          message: err,
          duration: 1500,
          position: "top",
          color: "danger",
        });
      });
  };

  return (
    <IonPage>
      {cow && (
        <CowForm
          cow={cow}
          title={`Edit ${cow.cowName}`}
          onFormSubmit={onEditCow}
        />
      )}
    </IonPage>
  );
}
