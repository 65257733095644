import {
  IonFab,
  IonPage,
  IonIcon,
  IonContent,
  IonFabButton,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { Tab, Tabs } from "@mui/material";
import { Virtuoso } from "react-virtuoso";
import { useEffect, useState } from "react";

import "./styles.css";

import BookCard from "./BookCard";
import { Book } from "../../../models";
import Header from "../../../components/Header";
import useBooksService from "../../../services/useBooksService";

export default function BooksList(): React.ReactElement {
  const { getBooksSub } = useBooksService();
  const [books, setBooks] = useState<Book[]>([]);
  const [currentSegment, setCurrentSegment] = useState<string>("active");

  useEffect(() => {
    const unsubscribe = getBooksSub((books) => {
      setBooks(
        books.sort((book1, book2) => {
          if (book1.createdAt! < book2.createdAt!) {
            return 1;
          }
          if (book1.createdAt! > book2.createdAt!) {
            return -1;
          }
          return 0;
        })
      );
    }, currentSegment);

    return () => unsubscribe();
  }, [getBooksSub, currentSegment]);

  return (
    <IonPage>
      <Header title={"Books"} />
      <IonContent className={"BooksList"} scrollY={false}>
        <div>
          <Tabs
            variant={"fullWidth"}
            value={currentSegment}
            onChange={(e, value) => {
              setCurrentSegment(value);
            }}
          >
            <Tab label={"Active"} value={"active"} />
            <Tab label={"Archived"} value={"archived"} />
          </Tabs>
          <Virtuoso
            data={books}
            itemContent={(index, book: Book) => (
              <BookCard key={book.id} book={book} />
            )}
          />
        </div>
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton routerLink="/add-book" size={"small"}>
            <IonIcon icon={add} size={"small"}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
}
