import { FirestoreDataConverter } from "firebase/firestore";

import { Cow } from "../../models";

const cowConverter: FirestoreDataConverter<Cow> = {
  toFirestore: (cow) => ({
    cowName: cow.cowName,
    dateOfPurchase: cow.dateOfPurchase,
    purchasePrice: cow.purchasePrice,
    location: cow.location,
    status: cow.status,
    createdAt: cow.createdAt,
    updatedAt: cow.updatedAt,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      cowName: data.cowName,
      dateOfPurchase: data.dateOfPurchase,
      purchasePrice: data.purchasePrice,
      location: data.location,
      status: data.status,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };
  },
};

export default cowConverter;
