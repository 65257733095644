import { IonCard, IonInput } from "@ionic/react";

import FormControl from "../../../components/FormControl/FormControl";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { Moment } from "moment/moment";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { Calf } from "../../../models";

export default function WeaningForm(): React.ReactElement {
  const formApi = useFormContext<Calf>();
  const { control, register, setValue } = formApi;
  return (
    <IonCard>
      <FormControl label={"Weaning Date"}>
        <Controller
          name="weaningDate"
          control={control}
          render={({ field }) => (
            <MobileDatePicker
              closeOnSelect
              value={field.value || null}
              inputFormat="MM/DD/YYYY"
              onChange={(e: Moment | null) => {
                setValue("weaningDate", e?.format("MM/DD/YYYY") || "", {
                  shouldDirty: true,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size={"small"}
                  fullWidth
                  style={{ marginTop: 10 }}
                />
              )}
            />
          )}
        />
      </FormControl>
      <FormControl label={"Weaning Weight"}>
        <IonInput
          {...register("weaningWeight", {
            valueAsNumber: true,
          })}
          type="number"
          onIonChange={(e) =>
            setValue("weaningWeight", e.target.value as number, {
              shouldDirty: true,
            })
          }
        />
      </FormControl>
    </IonCard>
  );
}
