import { v4 as uuidv4 } from "uuid";
import { IonPage, useIonRouter, useIonToast } from "@ionic/react";

import CowForm from "../CowForm";
import { Cow } from "../../../models";
import useCowsService from "../../../services/useCowsService";

export default function AddCow(): React.ReactElement {
  const router = useIonRouter();
  const [present] = useIonToast();
  const { addCow } = useCowsService();

  const onAddCow = async (data: Cow) => {
    try {
      const cowId = uuidv4();

      addCow(cowId, data)
        .then(() => {
          router.push(`/cows/${cowId}`);
        })
        .catch((err) => {
          present({
            message: err,
            duration: 1500,
            position: "top",
            color: "danger",
          });
        });
    } catch (err) {
      alert(err);
    }
  };

  return (
    <IonPage>
      <CowForm title="Add Cow" onFormSubmit={onAddCow} />
    </IonPage>
  );
}
