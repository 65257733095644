import moment from "moment";
import { FirestoreDataConverter, Timestamp } from "firebase/firestore";

import { Calf } from "../../models";

const calfConverter: FirestoreDataConverter<Calf> = {
  toFirestore: (calf) => ({
    cowName: calf.cowName,
    calfName: calf.calfName,
    bookId: calf.bookId,
    cowId: calf.cowId,
    dateOfBirth: calf.dateOfBirth,
    gender: calf.gender,
    weight: calf.weight,
    isCull: calf.isCull,
    isDead: calf.isDead,
    isPairout: calf.isPairout,
    sireId: calf.sireId,
    comment: calf.comment,
    weaningDate: calf.weaningDate,
    twinId: calf.twinId,
    createdAt: calf.createdAt,
    updatedAt: calf.updatedAt,
    color: calf.color,
    tagColor: calf.tagColor,
    adoptedBy: calf.adoptedBy,
    calvinEase: calf.calvinEase,
    isTwin: calf.isTwin,
    udderGrade: calf.udderGrade,
    status: calf.status,
    weaningWeight: calf.weaningWeight,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      cowName: data.cowName,
      calfName: data.calfName,
      bookId: data.bookId,
      cowId: data.cowId,
      dateOfBirth: data.dateOfBirth,
      gender: data.gender,
      weight: data.weight,
      isCull: data.isCull,
      isDead: data.isDead,
      isPairout: data.isPairout,
      sireId: data.sireId,
      comment: data.comment,
      weaningDate: data.weaningDate,
      twinId: data.twinId,
      dateCreated: data.dateCreated,
      color: data.color,
      tagColor: data.tagColor,
      adoptedBy: data.adoptedBy,
      calvinEase: data.calvinEase,
      isTwin: data.isTwin,
      udderGrade: data.udderGrade,
      status: data.status,
      createdAt: moment(
        new Timestamp(
          data.createdAt.seconds,
          data.createdAt.nanoseconds
        ).toDate()
      ).format("MM/DD/YYYY"),
      updatedAt: moment(
        new Timestamp(
          data.updatedAt.seconds,
          data.updatedAt.nanoseconds
        ).toDate()
      ).format("MM/DD/YYYY"),
      weaningWeight: data.weaningWeight,
    };
  },
};

export default calfConverter;
