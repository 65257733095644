import {
  IonFab,
  IonIcon,
  IonPage,
  IonContent,
  IonFabButton,
  IonSearchbar,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { Virtuoso } from "react-virtuoso";
import { useEffect, useState } from "react";

import "./styles.css";

import CowCard from "./CowCard";
import { Cow } from "../../../models";
import useCowsService from "../../../services/useCowsService";
import Header from "../../../components/Header";

export default function CowsList(): React.ReactElement {
  const { getCowsSub } = useCowsService();
  const [cows, setCows] = useState<Cow[]>([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getCowsSub((cows) => {
      setCows(
        cows.sort((cow1, cow2) => {
          if (cow1.cowName?.toLowerCase() < cow2.cowName?.toLowerCase()) {
            return -1;
          }
          if (cow1.cowName?.toLowerCase() > cow2.cowName?.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      );
    });
  }, [getCowsSub]);

  const filterCows = (cows: Cow[]) => {
    if (searchValue && searchValue.length > 0) {
      return cows.filter((cow) => {
        return cow.cowName?.toLowerCase().includes(searchValue.toLowerCase());
      });
    }

    return cows;
  };

  return (
    <IonPage>
      <Header title={"Cows"}>
        <Header.SubHeader>
          <IonSearchbar
            value={searchValue}
            onIonChange={(e) => {
              setSearchValue(e.target.value || "");
            }}
            className="ion-padding-horizontal"
          />
        </Header.SubHeader>
      </Header>
      <IonContent className={"CowsList"} scrollY={false}>
        <div>
          <div className={"CowsListHeader"}>
            <span>Cow name</span>
            <span>Location</span>
          </div>
          <Virtuoso
            data={filterCows(cows)}
            itemContent={(index, cow: Cow) => (
              <CowCard key={cow.id} cow={cow} />
            )}
          />
        </div>
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton routerLink="/add-cow">
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
}
