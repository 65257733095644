import { FirestoreDataConverter } from "firebase/firestore";

import { Sire } from "../../models";

const sireConverter: FirestoreDataConverter<Sire> = {
  toFirestore: (sire) => ({
    sireName: sire.sireName,
    dateOfPurchase: sire.dateOfPurchase,
    location: sire.location,
    cost: sire.cost,
    status: sire.status,
    createdAt: sire.createdAt,
    updatedAt: sire.updatedAt,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      sireName: data.sireName,
      dateOfPurchase: data.dateOfPurchase,
      cost: data.cost,
      status: data.status,
      location: data.location,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };
  },
};

export default sireConverter;
