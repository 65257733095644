import {
  IonIcon,
  IonButton,
  IonCard,
  IonLabel,
  useIonRouter,
  useIonToast,
  useIonLoading,
} from "@ionic/react";
import { useState } from "react";
import { ellipsisHorizontalOutline } from "ionicons/icons";

import "./styles.css";

import MoreModal from "./MoreModal";
import { Book } from "../../../../models";
import useExport from "../../../../hooks/useExport";
import { CalfReport } from "../../../../models/calf";
import useBookCalvesReport from "../../../../hooks/useBookCalvesReport";

interface BookCardProps {
  book: Book;
}

const BookCard = ({ book }: BookCardProps) => {
  const router = useIonRouter();
  const [present] = useIonToast();
  const [presentLoader, dismiss] = useIonLoading();
  const { getReport } = useBookCalvesReport(book.id);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const { exportDataCsv, exportDataPdf, exportData } = useExport();

  const onCardClicked = (e: any) => {
    if (e.target.id === "more") {
      return setIsMoreOpen(true);
    }

    router.push(`/books/${book.id}`, "forward");
  };

  const onExportBook = async () => {
    presentLoader();
    try {
      const reportData = await getReport();
      const csv = exportDataCsv<CalfReport>(reportData, [
        { label: "Cow ID", key: "cowName" },
        { label: "Calf ID", key: "calfName" },
        { label: "Weight", key: "weight" },
        { label: "Gender", key: "gender" },
        { label: "Date", key: "dateOfBirth" },
        { label: "Sire", key: "sireName" },
        { label: "Color", key: "color" },
        { label: "Calving Ease", key: "calvinEase" },
        { label: "Comment", key: "comment" },
        { label: "Udder Grade", key: "udderGrade" },
        { label: "Tag Color", key: "tagColor" },
        { label: "Dead", key: "isDead" },
        { label: "Pairout", key: "isPairout" },
        { label: "Cull", key: "isCull" },
        { label: "Weaning Weight", key: "weaningWeight" },
        { label: "Weaning Date", key: "weaningDate" },
      ]);
      const pdf = exportDataPdf<CalfReport>(
        book.name,
        [
          { label: "Cow ID", key: "cowName", width: "auto" },
          { label: "Calf ID", key: "calfName", width: "auto" },
          { label: "Weight", key: "weight", width: "auto" },
          { label: "Gender", key: "gender", width: "auto" },
          { label: "Date", key: "dateOfBirth", width: "auto" },
          { label: "Sire", key: "sireName", width: "auto" },
          { label: "Color", key: "color", width: "auto" },
          { label: "Calving Ease", key: "calvinEase", width: "auto" },
          { label: "Comment", key: "comment", width: "auto" },
          { label: "Udder Grade", key: "udderGrade", width: "auto" },
          { label: "Tag Color", key: "tagColor", width: "auto" },
          { label: "Dead", key: "isDead", width: "auto" },
          { label: "Pairout", key: "isPairout", width: "auto" },
          { label: "Cull", key: "isCull", width: "auto" },
          { label: "Weaning Weight", key: "weaningWeight", width: "auto" },
          { label: "Weaning Date", key: "weaningDate", width: "auto" },
        ],
        reportData
      );
      await exportData(`Book ${book.name}`, csv, pdf);

      present({
        position: "top",
        duration: 1500,
        color: "success",
        message: "Data exported into CSV and PDF.",
      });
    } catch (err) {
      console.log(err);
      present({
        duration: 1500,
        position: "top",
        color: "danger",
        message: "Something went wrong when exporting data.",
      });
    }

    dismiss();
  };

  return (
    <>
      <IonCard className={`BookCard`} onClick={(e) => onCardClicked(e)}>
        <h4>{book.name}</h4>
        <div>
          <div className="BookCardField">
            <IonLabel color={"#878C9D"}>{`${
              book.calvesCount || 0
            } calves`}</IonLabel>
          </div>
        </div>
        <IonButton
          id="more"
          fill="clear"
          className="ion-no-padding ion-no-margin"
          size={"small"}
        >
          <IonIcon icon={ellipsisHorizontalOutline} size="small" id="more" />
        </IonButton>
      </IonCard>

      <MoreModal
        book={book}
        isOpen={isMoreOpen}
        onExportBook={() => {
          onExportBook();
        }}
        close={() => setIsMoreOpen(false)}
      />
    </>
  );
};

export default BookCard;
