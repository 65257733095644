import { IonCard, IonCardContent, IonLabel } from "@ionic/react";

import "./styles.css";

import { Note } from "../../../../models";

interface NoteCardProps {
  note: Note;
}

const NoteCard = ({ note }: NoteCardProps) => {
  const shortText = note.text.substring(0, 100) + "...";
  return (
    <IonCard routerLink={`/notes/${note.id}`}>
      <IonCardContent>
        <div className="NoteCardContent">
          <IonLabel>{shortText}</IonLabel>
          <div className={"NoteDate"}>
            <IonLabel color={"medium"}>Date</IonLabel>
            <IonLabel color={"primary"}> {note.date}</IonLabel>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default NoteCard;
