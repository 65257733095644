import {
  doc,
  query,
  where,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  collection,
  onSnapshot,
  Unsubscribe,
} from "firebase/firestore";
import { useCallback } from "react";

import db from "../db/firebase";
import { Book, Calf } from "../models";
import { getCurrentTimestamp } from "../utils";
import { useAuth } from "../Auth/AuthProvider";
import { bookConverter, calfConverter } from "../db/converters";

export default function useBooksService() {
  const { currentUser } = useAuth();
  const orgId = currentUser?.uid!;

  const getBook = useCallback(
    async (bookId: string): Promise<Book | undefined> => {
      const bookDocRef = doc(
        db,
        `organizations/${orgId}/books/${bookId}`
      ).withConverter(bookConverter);
      const bookDoc = await getDoc(bookDocRef);
      return bookDoc.data();
    },
    [orgId]
  );

  const getBooksSub = useCallback(
    (cb: (books: Book[]) => void, status: string = "active"): Unsubscribe => {
      const booksCollectionRef = query(
        collection(db, `organizations/${orgId}/books`),
        where("status", "==", status)
      ).withConverter(bookConverter);
      return onSnapshot(booksCollectionRef, (snapshot) => {
        cb(
          snapshot.docs
            .map((doc) => doc.data())
            .sort((bookA, bookB) => {
              return (bookA.createdAt || "") > (bookB.createdAt || "") ? 1 : -1;
            })
        );
      });
    },
    [orgId]
  );

  const addBook = useCallback(
    (book: Book) => {
      addDoc(
        collection(db, `organizations/${orgId}/books`).withConverter(
          bookConverter
        ),
        {
          ...book,
          status: "active",
          createdAt: getCurrentTimestamp(),
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const updateBook = useCallback(
    (bookId: string, book: Partial<Book>): void => {
      updateDoc(
        doc(db, `organizations/${orgId}/books`, bookId).withConverter(
          bookConverter
        ),
        {
          ...book,
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const updateCalvesCount = useCallback(
    async (bookId: string, difference: number) => {
      const book = await getBook(bookId);
      let calvesCount = book?.calvesCount || 0;
      calvesCount = calvesCount + difference;
      console.log(calvesCount);
      updateDoc(
        doc(db, `organizations/${orgId}/books`, bookId).withConverter(
          bookConverter
        ),
        {
          calvesCount,
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId, getBook]
  );

  const getBooksCalves = useCallback(
    async (bookId: string): Promise<Calf[]> => {
      const calvesCollectionRef = query(
        collection(db, `organizations/${orgId}/calves`),
        where("bookId", "==", bookId)
      ).withConverter(calfConverter);
      const calvesDocs = await getDocs(calvesCollectionRef);
      return calvesDocs.docs.map((doc) => doc.data());
    },
    [orgId]
  );

  const getBookCalvesSub = useCallback(
    (
      bookId: string,
      cb: (calves: Calf[]) => void,
      status: string = "active"
    ): Unsubscribe => {
      const calvesCollectionRef = query(
        collection(db, `organizations/${orgId}/calves`),
        where("bookId", "==", bookId),
        where("status", "==", status)
      ).withConverter(calfConverter);
      return onSnapshot(calvesCollectionRef, (snapshot) => {
        cb(snapshot.docs.map((doc) => doc.data()));
      });
    },
    [orgId]
  );

  return {
    addBook,
    getBook,
    updateBook,
    getBooksSub,
    getBooksCalves,
    getBookCalvesSub,
    updateCalvesCount,
  };
}
