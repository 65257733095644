import { IonButton, IonContent, IonItem, IonModal } from "@ionic/react";
import { useRef } from "react";
import Header from "../../../../components/Header";
//import useCowsService from "../../../../services/useCowsService";

interface MoreModalProps {
  cowId: string;
  isOpen: boolean;
  close: () => void;
}

export default function MoreModal({
  cowId,
  close,
  isOpen,
}: MoreModalProps): React.ReactElement {
  //const { deleteCow } = useCowsService();
  const modal = useRef<HTMLIonModalElement>(null);

  // const onDeleteCow = () => {
  //   deleteCow(cowId);
  // };

  return (
    <IonModal isOpen={isOpen} ref={modal} onWillDismiss={(e) => close()}>
      <Header
        title={"More"}
        withBorder={false}
        rightComponent={
          <IonButton
            size={"small"}
            fill={"clear"}
            className="ion-text-uppercase ion-no-margin ion-no-padding"
            onClick={() => modal.current?.dismiss()}
          >
            Close
          </IonButton>
        }
      />
      <IonContent>
        <IonItem
          routerLink={`/cows/${cowId}/summary`}
          onClick={() => {
            modal.current?.dismiss();
          }}
        >
          Summary Report of Calves Born
        </IonItem>
        <IonItem
          routerLink="/cows/batches"
          onClick={() => {
            modal.current?.dismiss();
          }}
        >
          Batch Vaccines
        </IonItem>
      </IonContent>
    </IonModal>
  );
}
