import { FirestoreDataConverter } from "firebase/firestore";

import { Book } from "../../models";

const bookConverter: FirestoreDataConverter<Book> = {
  toFirestore: (book) => ({
    name: book.name,
    status: book.status,
    createdAt: book.createdAt,
    updatedAt: book.updatedAt,
    calvesCount: book.calvesCount,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      name: data.name,
      status: data.status,
      summary: data.summary,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      calvesCount: data.calvesCount,
    };
  },
};

export default bookConverter;
