import { IonPage, IonSpinner } from "@ionic/react";

interface LoadingScreenProps {
  message?: string;
}
export default function LoadingScreen({
  message = "",
}: LoadingScreenProps): React.ReactElement {
  return (
    <IonPage className="ion-align-items-center ion-justify-content-center ion-text-center">
      <IonSpinner />
      {message}
    </IonPage>
  );
}
