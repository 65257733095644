import moment from "moment";
import { Timestamp } from "firebase/firestore";

export function getCurrentDate() {
  return moment().format("MM/DD/YYYY");
}

export function getCurrentTimestamp() {
  return Timestamp.now();
}

export function formatDateFromDb(date?: string) {
  if (date) {
    return moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");
  }
}

export function formatDateToDb(date: string) {
  if (date) {
    return moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
  }
}

export function capitalizeFirstLetter(str: string) {
  return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}

export function toUpperCase(str: string) {
  return str.length ? str.toUpperCase() : str;
}
