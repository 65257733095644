import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { IonPage, useIonRouter } from "@ionic/react";

import SireForm from "../SireForm";
import { Sire } from "../../../models";
import useSiresService from "../../../services/useSiresService";

type RouteParams = { sireId: string };

export default function EditSire(): React.ReactElement {
  const router = useIonRouter();
  const { getSire, updateSire } = useSiresService();
  const [sire, setSire] = useState<Sire>();
  const { sireId } = useParams<RouteParams>();

  useEffect(() => {
    async function fetchSire() {
      const sire = await getSire(sireId);
      setSire(sire);
    }

    fetchSire();
  }, [sireId, getSire]);

  const onEditSire = async (data: Sire) => {
    updateSire(sireId, data);
    router.push(`/sires`);
  };

  return (
    <IonPage>
      {sire && (
        <SireForm
          title={`Edit ${sire.sireName}`}
          sire={sire}
          onFormSubmit={onEditSire}
        />
      )}
    </IonPage>
  );
}
