import {
  IonCard,
  IonPage,
  IonItem,
  IonInput,
  IonLabel,
  IonButton,
  IonContent,
  useIonRouter,
} from "@ionic/react";
import React, { useState } from "react";

import Subscription from "./Subscription";
import { Tab, Tabs } from "@mui/material";
import { EditProfile } from "./EditProfile";
import Header from "../../components/Header";
import { useAuth } from "../../Auth/AuthProvider";

const Profile: React.FC = () => {
  let router = useIonRouter();
  const auth = useAuth();
  const [editing, setEditing] = useState(false);
  const [currengPage, setCurrentPage] = useState("profile");

  const logout = async () => {
    auth.logout();
    router.push("/login");
  };

  return (
    <IonPage>
      <Header
        title={`Hi ${auth.currentOrg?.firstName} ${auth.currentOrg?.lastName}`}
      />
      <IonContent fullscreen>
        <Tabs
          variant={"fullWidth"}
          value={currengPage}
          onChange={(e, value) => {
            setCurrentPage(value);
          }}
        >
          <Tab label={"Profile"} value={"profile"} />
          <Tab label={"Subscription"} value={"subscription"} />
        </Tabs>
        {currengPage === "profile" ? (
          <IonCard>
            {auth.currentOrg && (
              <>
                <IonItem>
                  <IonLabel>Email</IonLabel>
                  <IonInput disabled value={auth.currentUser?.email} />
                </IonItem>
                <EditProfile
                  editable={editing}
                  organization={auth.currentOrg}
                  onEditDone={() => setEditing(false)}
                />
                {!editing && (
                  <IonButton
                    fill="clear"
                    expand="full"
                    type="button"
                    onClick={() => setEditing(true)}
                  >
                    Edit Profile
                  </IonButton>
                )}
              </>
            )}
            <IonButton
              fill="clear"
              expand="full"
              type="button"
              onClick={logout}
            >
              Logout
            </IonButton>
          </IonCard>
        ) : (
          <div>
            <Subscription />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Profile;
