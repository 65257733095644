import {
  IonFab,
  IonIcon,
  IonPage,
  IonTitle,
  IonHeader,
  IonButton,
  IonToolbar,
  IonButtons,
  IonContent,
  IonFabButton,
} from "@ionic/react";
import { useState, useEffect } from "react";
import { chevronBackOutline, add } from "ionicons/icons";

import { BatchList } from "../../Batches";
import useVaccinesService from "../../../services/useVaccinesService";

export default function CowsBatchesList() {
  const { getBatches } = useVaccinesService();
  const [batches, setBatches] = useState<any[]>([]);

  useEffect(() => {
    async function fetchBatches() {
      const batches = await getBatches("isCowBatch");
      setBatches(batches);
    }
    fetchBatches();
  }, [getBatches]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton fill="clear" routerLink="/cows">
              <IonIcon icon={chevronBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Cows Batches</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <BatchList batches={batches} />
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton routerLink="/cows/add-batch-vaccine">
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
}
