import { useEffect, useState } from "react";

import { Book, Cow, Sire } from "../../../models";
import useBooksService from "../../../services/useBooksService";
import useCowsService from "../../../services/useCowsService";
import useSiresService from "../../../services/useSiresService";

export function useCalfForm() {
  const { getSires } = useSiresService();
  const { getCowsSub } = useCowsService();
  const { getBooksSub } = useBooksService();
  const [cows, setCows] = useState<Cow[]>([]);
  const [books, setBooks] = useState<Book[]>([]);
  const [sires, setSires] = useState<Sire[]>([]);

  useEffect(() => {
    async function fetchSires() {
      const sires = await getSires();
      setSires(
        sires.sort((sire1, sire2) => {
          if (sire1.sireName.toLowerCase() < sire2.sireName.toLowerCase()) {
            return -1;
          }
          if (sire1.sireName.toLowerCase() > sire2.sireName.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      );
    }

    fetchSires();
    getCowsSub(setCows);
    getBooksSub(setBooks);
  }, [getSires, getCowsSub, getBooksSub]);

  return {
    cows,
    books,
    sires,
    setSires,
  };
}
