import {
  IonFab,
  IonIcon,
  IonPage,
  IonTitle,
  IonButton,
  IonHeader,
  IonButtons,
  IonContent,
  IonToolbar,
  IonFabButton,
} from "@ionic/react";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { add, chevronBackOutline } from "ionicons/icons";

import { BatchList } from "../../../Batches";
import useVaccinesService from "../../../../services/useVaccinesService";

type RouteParams = { bookId: string };

export default function BookBatchesList() {
  const { bookId } = useParams<RouteParams>();
  const { getBatches } = useVaccinesService();
  const [batches, setBatches] = useState<any[]>([]);

  useEffect(() => {
    async function fetchBatches() {
      const batches = await getBatches("isBookBatch", bookId);
      setBatches(batches);
    }
    fetchBatches();
  }, [bookId, getBatches]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton fill="clear" routerLink="/books">
              <IonIcon icon={chevronBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Book Batch Vaccines</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <BatchList batches={batches} />
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton routerLink="/books/add-batch-vaccine">
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
}
