import {
  IonFab,
  IonIcon,
  IonPage,
  IonContent,
  IonFabButton,
  IonSearchbar,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { Virtuoso } from "react-virtuoso";
import { useEffect, useState } from "react";

import "./styles.css";

import SireCard from "./SireCard";
import { Sire } from "../../../models";
import useSiresService from "../../../services/useSiresService";
import Header from "../../../components/Header";

export default function SiresList(): React.ReactElement {
  const { getSiresSub } = useSiresService();
  const [sires, setSires] = useState<Sire[]>([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getSiresSub((sires) => {
      setSires(
        sires.sort((sire1, sire2) => {
          if (sire1.sireName.toLowerCase() < sire2.sireName.toLowerCase()) {
            return -1;
          }
          if (sire1.sireName.toLowerCase() > sire2.sireName.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      );
    });
  }, [getSiresSub]);

  const filterSires = (sires: Sire[]) => {
    if (searchValue && searchValue.length > 0) {
      return sires.filter((sire) => {
        return sire.sireName?.toLowerCase().includes(searchValue.toLowerCase());
      });
    }

    return sires;
  };

  return (
    <IonPage>
      <Header title={"Sires"}>
        <Header.SubHeader>
          <IonSearchbar
            value={searchValue}
            onIonChange={(e) => {
              setSearchValue(e.target.value || "");
            }}
            className="ion-padding-horizontal"
          />
        </Header.SubHeader>
      </Header>
      <IonContent className={"SiresList"} scrollY={false}>
        <div>
          <div className={"SiresListHeader"}>
            <span>Sire name</span>
            <span>Location</span>
            <span>Date of purchase</span>
          </div>
          <Virtuoso
            data={filterSires(sires)}
            itemContent={(index, sire: Sire) => (
              <SireCard key={sire.id} sire={sire} />
            )}
          />
        </div>
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton routerLink="/add-sire">
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
}
