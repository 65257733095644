import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { IonPage, useIonRouter } from "@ionic/react";

import NoteForm from "../NoteForm";
import { Note } from "../../../models";
import useNotesService from "../../../services/useNotesService";

type RouteParams = { noteId: string };

export default function EditNote(): React.ReactElement {
  const router = useIonRouter();
  const { getNote, updateNote } = useNotesService();
  const [note, setNote] = useState<Note>();
  const { noteId } = useParams<RouteParams>();

  useEffect(() => {
    async function fetchNote() {
      const note = await getNote(noteId);
      setNote(note);
    }

    fetchNote();
  }, [noteId, getNote]);

  const onEditNote = async (data: Note) => {
    updateNote(noteId, data);
    router.push(`/notes`);
  };

  return (
    <IonPage>
      {note && (
        <NoteForm title="Edit Note" note={note} onFormSubmit={onEditNote} />
      )}
    </IonPage>
  );
}
