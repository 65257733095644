import {
  IonCard,
  IonInput,
  IonButton,
  IonContent,
  IonTextarea,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import moment, { Moment } from "moment";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import "./styles.css";

import Header from "../../Header";
import { Vaccine } from "../../../models";
import FormControl from "../../FormControl/FormControl";

interface VaccineFormProps {
  title: string;
  backLink: string;
  quickViewForm?: boolean;
  vaccine?: Partial<Vaccine>;
  onFormSubmit: (data: Vaccine) => void;
}

export default function VaccineForm({
  title,
  vaccine,
  backLink,
  onFormSubmit,
  quickViewForm = false,
}: VaccineFormProps): React.ReactElement {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<Vaccine>({
    defaultValues: {
      shotName: vaccine?.shotName || "",
      dose: vaccine?.dose || 0,
      date: vaccine?.date || moment().format("MM/DD/YYYY"),
      comment: vaccine?.comment || "",
    },
  });

  const onSubmit = handleSubmit((data) => {
    onFormSubmit(data);
  });

  const onGoBack = () => {
    if (isDirty) {
      return presentAlert({
        header: "You have unsaved changes, are you sure you want to leave?",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "OK",
            role: "confirm",
            handler: () => {
              reset();
              router.push(backLink, "back");
            },
          },
        ],
      });
    }

    router.push(backLink, "back");
  };

  return (
    <>
      {!quickViewForm && (
        <Header
          title={title}
          onGoBack={onGoBack}
          rightComponent={
            <IonButton
              fill="clear"
              type="submit"
              disabled={!isValid}
              onClick={onSubmit}
            >
              Save
            </IonButton>
          }
        />
      )}
      <IonContent>
        <form onSubmit={onSubmit} className="VaccineForm">
          <IonCard>
            <FormControl label={"Shot Name*"}>
              <IonInput
                {...register("shotName", {
                  required: "This is a required field",
                  minLength: 1,
                })}
                onIonChange={(e) =>
                  setValue("shotName", e.target.value as string, {
                    shouldValidate: true,
                  })
                }
                placeholder="Shot Name"
              />
            </FormControl>
            <FormControl label={"Dose*"}>
              <IonInput
                {...register("dose", {
                  required: "This is a required field",
                })}
                onIonChange={(e) =>
                  setValue("dose", e.detail.value as any, {
                    shouldValidate: true,
                  })
                }
                placeholder="Dose"
              />
            </FormControl>
            <FormControl label={"Date"}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <MobileDatePicker
                    closeOnSelect
                    value={field.value || null}
                    inputFormat="MM/DD/YYYY"
                    onChange={(e: Moment | null) => {
                      setValue("date", e?.format("MM/DD/YYYY") || "", {
                        shouldDirty: true,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size={"small"}
                        fullWidth
                        style={{ marginTop: 10 }}
                      />
                    )}
                  />
                )}
              />
            </FormControl>
            <FormControl label={"Comment"}>
              <IonTextarea
                {...register("comment")}
                placeholder="Comment"
                rows={3}
              />
            </FormControl>
          </IonCard>
          {quickViewForm && (
            <IonButton type="submit" size="small">
              Save Vaccine
            </IonButton>
          )}
        </form>
      </IonContent>
    </>
  );
}
