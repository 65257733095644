import moment from "moment";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { IonButton, IonContent, IonPage, useIonRouter } from "@ionic/react";

import RecordForm from "./Form";
import Form from "../BreedingForm/Form";
import { Breeding } from "../../../../models";
import Header from "../../../../components/Header";
import { useGoBack } from "../../../../hooks/useGoBack";
import { BreedingRecord } from "../../../../models/breeding";
import useBreedingService from "../../../../services/useBreedingService";

type RouteParams = { cowId: string };
export default function BreedingRecordForm() {
  const router = useIonRouter();
  const { cowId } = useParams<RouteParams>();
  const { addBreed } = useBreedingService();

  const recordFormApi = useForm<BreedingRecord>({
    defaultValues: {
      gender: "",
      twins: false,
      pregnant: false,
      expectedDateBirth: undefined,
    },
  });

  const breedingFormApi = useForm<Breeding>({
    defaultValues: {
      aiTech: "",
      sireId: "",
      attemptDate: undefined,
    },
  });

  const {
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { isValid, isDirty },
  } = recordFormApi;

  const { watch, getValues: breedingData } = breedingFormApi;

  const attemptDate = watch("attemptDate");

  useEffect(() => {
    if (attemptDate && !getValues().expectedDateBirth) {
      const momentDate = moment(attemptDate, "MM/DD/YYYY");
      setValue(
        "expectedDateBirth",
        momentDate.add(283, "days").format("MM/DD/YYYY"),
        {
          shouldDirty: true,
        }
      );
    }
  }, [setValue, attemptDate, getValues]);

  const goBack = useGoBack({
    guardValue: isDirty,
    navigateTo: `/cows/${cowId}`,
    routerDirection: "back",
    onOkGuardHandler: () => {
      reset();
    },
  });

  const onSubmit = handleSubmit((data) => {
    const breedingAttemptId = uuidv4();
    const breedingRecord: BreedingRecord = {
      ...data,
      cowId,
      breedingAttempts: [
        {
          ...breedingData(),
          id: breedingAttemptId,
        },
      ],
    };

    addBreed({
      ...breedingRecord,
    });
    router.push(`/cows/${cowId}`);
  });

  return (
    <IonPage>
      <Header
        title={"Add Breeding Record"}
        onGoBack={goBack}
        rightComponent={
          <IonButton
            fill="clear"
            type="submit"
            onClick={onSubmit}
            disabled={!isValid}
            style={{ color: "#4888AE" }}
          >
            Save
          </IonButton>
        }
      />
      <IonContent>
        <FormProvider {...recordFormApi}>
          <RecordForm />
        </FormProvider>
        <FormProvider {...breedingFormApi}>
          <Form />
        </FormProvider>
      </IonContent>
    </IonPage>
  );
}
