import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { IonCard, IonInput, IonButton, IonCheckbox } from "@ionic/react";

import { OrgData } from "../../models/organization";
import FormControl from "../../components/FormControl/FormControl";
import useOrganizationsService from "../../services/useOrganizationsService";

interface EditProfileProps {
  editable: boolean;
  organization: OrgData;
  onEditDone: () => void;
}

export const EditProfile = ({
  editable,
  onEditDone,
  organization,
}: EditProfileProps) => {
  const { updateOrganization } = useOrganizationsService();
  const {
    control,
    trigger,
    register,
    setValue,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<OrgData>({
    defaultValues: {
      companyName: organization.companyName,
      companyAddress: organization.companyAddress,
      companyZipCode: organization.companyZipCode,
      companyCity: organization.companyCity,
      companyState: organization.companyState,
      firstName: organization.firstName,
      lastName: organization.lastName,
      cowName: organization.cowName,
      calfName: organization.calfName,
      phoneNumber: organization.phoneNumber,
    },
  });

  useEffect(() => {
    if (editable) {
      trigger();
    }
  }, [trigger, editable]);

  const handleSignup = handleSubmit(async (data: OrgData) => {
    try {
      updateOrganization(data);
      onEditDone();
    } catch (err) {
      console.log("err", err);
    }
  });

  return (
    <form onSubmit={handleSignup}>
      <IonCard>
        <FormControl label={"First Name"} required error={!!errors.firstName}>
          <IonInput
            {...register("firstName", {
              required: "This is a required field",
            })}
            disabled={!editable}
            onIonChange={(e) =>
              setValue("firstName", e.detail.value!, {
                shouldValidate: true,
              })
            }
          />
        </FormControl>
        <FormControl label={"Last Name"} required error={!!errors.lastName}>
          <IonInput
            {...register("lastName", {
              required: "This is a required field",
            })}
            disabled={!editable}
            onIonChange={(e) =>
              setValue("lastName", e.detail.value!, {
                shouldValidate: true,
              })
            }
          />
        </FormControl>
        <FormControl label={"Buisness Name"}>
          <IonInput
            {...register("companyName", {})}
            disabled={!editable}
            onIonChange={(e) =>
              setValue("companyName", e.detail.value!, {
                shouldValidate: true,
              })
            }
          />
        </FormControl>
        <FormControl
          required
          label={"Phone Number"}
          error={!!errors.phoneNumber}
        >
          <IonInput
            {...register("phoneNumber", {
              maxLength: 10,
              required: "This is a required field",
            })}
            onIonChange={(e) => {
              setValue("phoneNumber", e.detail.value!, {
                shouldValidate: true,
              });
            }}
            disabled={!editable}
          />
        </FormControl>
        <FormControl label={"Address"}>
          <IonInput
            {...register("companyAddress", {
              required: "This is a required field",
            })}
            onIonChange={(e) => {
              setValue("companyAddress", e.detail.value!, {
                shouldValidate: true,
              });
            }}
            disabled={!editable}
          />
        </FormControl>

        <FormControl label={"City"}>
          <IonInput {...register("companyCity", {})} disabled={!editable} />
        </FormControl>
        <FormControl label={"State"}>
          <IonInput {...register("companyState", {})} disabled={!editable} />
        </FormControl>
        <FormControl label={"Zip Code"}>
          <IonInput {...register("companyZipCode", {})} disabled={!editable} />
        </FormControl>

        <FormControl label={"Show Cow ID in Calves Page"}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Controller
              name="cowName"
              control={control}
              render={({ field }) => (
                <IonCheckbox
                  id="cowName"
                  checked={field.value}
                  value={field.value}
                  onIonChange={(e) => {
                    setValue("cowName", e.target.checked);
                  }}
                  disabled={!editable}
                />
              )}
            />
          </div>
        </FormControl>

        <FormControl label={"Show Calf ID in Calves Page"}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Controller
              name="calfName"
              control={control}
              render={({ field }) => (
                <IonCheckbox
                  id="calfName"
                  checked={field.value}
                  value={field.value}
                  onIonChange={(e) => {
                    setValue("calfName", e.target.checked);
                  }}
                  disabled={!editable}
                />
              )}
            />
          </div>
        </FormControl>

        {editable && (
          <IonButton
            fill="clear"
            expand="full"
            type="submit"
            disabled={!isValid}
          >
            Save
          </IonButton>
        )}
      </IonCard>
    </form>
  );
};
