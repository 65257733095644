import { RouterDirection } from "@ionic/core";
import { useIonAlert, useIonRouter } from "@ionic/react";

interface Props {
  navigateTo: string;
  guardValue?: boolean;
  onOkGuardHandler?: () => void;
  routerDirection?: RouterDirection;
}

export function useGoBack({
  navigateTo,
  guardValue,
  routerDirection,
  onOkGuardHandler,
}: Props) {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const goBack = () => {
    if (guardValue && onOkGuardHandler) {
      return presentAlert({
        header: "You have unsaved changes, are you sure you want to leave?",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "OK",
            role: "confirm",
            handler: () => {
              onOkGuardHandler();
              router.push(navigateTo, routerDirection);
            },
          },
        ],
      });
    }

    router.push(navigateTo, routerDirection);
  };

  return goBack;
}
