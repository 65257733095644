import { useCallback } from "react";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";

import db from "../db/firebase";
import { useAuth } from "../Auth/AuthProvider";
import { OrgData } from "../models/organization";
import { getCurrentDate } from "../utils";

export default function useOrganizationsService() {
  const { currentUser } = useAuth();
  const orgId = currentUser?.uid!;

  const getOrganization = useCallback(async (): Promise<OrgData> => {
    const organizationDocRef = doc(db, `organizations/${orgId}`);
    const organizationDoc = await getDoc(organizationDocRef);
    return organizationDoc.data() as OrgData;
  }, [orgId]);

  const updateOrganization = useCallback(
    (orgData: Partial<OrgData>): void => {
      updateDoc(doc(db, "organizations", orgId), {
        ...orgData,
        updatedAt: getCurrentDate(),
      });
    },
    [orgId]
  );

  return {
    getOrganization,
    updateOrganization,
  };
}

export const createOrganization = async (
  uid: string,
  orgData: OrgData
): Promise<void> => {
  await setDoc(doc(db, `organizations`, uid), {
    ...orgData,
    trialDays: 14,
    createdAt: getCurrentDate(),
    updatedAt: getCurrentDate(),
  });
};
