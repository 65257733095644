import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { IonPage, useIonRouter } from "@ionic/react";

import { Vaccine } from "../../../models";
import VaccineForm from "../../../components/Vaccines/VaccineForm";
import useVaccinesService from "../../../services/useVaccinesService";

type RouteParams = { bookId: string; calfId: string; vaccineId: string };

export default function EditCalfVaccine(): React.ReactElement {
  const router = useIonRouter();
  const [vaccine, setVaccine] = useState<Vaccine>();
  const { getVaccine, updateVaccine } = useVaccinesService();
  const { bookId, calfId, vaccineId } = useParams<RouteParams>();

  useEffect(() => {
    async function fetchVaccine() {
      const vaccine = await getVaccine(vaccineId);
      setVaccine(vaccine);
    }

    fetchVaccine();
  }, [calfId, vaccineId, getVaccine]);

  const onEditCalfVaccine = async (data: Vaccine) => {
    updateVaccine(vaccineId, data);
    router.push(`/books/${bookId}/calves/${calfId}`);
  };

  return (
    <IonPage>
      {vaccine && (
        <VaccineForm
          vaccine={vaccine}
          title="Edit Vaccine"
          onFormSubmit={onEditCalfVaccine}
          backLink={`/books/${bookId}/calves/${calfId}`}
        />
      )}
    </IonPage>
  );
}
