import {
  IonFab,
  IonIcon,
  IonPage,
  IonContent,
  IonFabButton,
  useIonRouter,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { Virtuoso } from "react-virtuoso";

import "./styles.css";

import CalfCard from "./CalfCard";
import { Calf } from "../../../models";
import BookToolbar from "./BookToolbar";
import { useParams } from "react-router";
import { useBookDetail } from "./useBookDetail";
import { BookSearchProvider } from "./BookToolbar/BookSearchProvider";
import Header from "../../../components/Header";
//import useBooksService from "../../../services/useBooksService";

type RouteParams = { bookId: string };

function BookDetail(): React.ReactElement {
  const navigate = useIonRouter();
  // const [presentAlert] = useIonAlert();
  // const { updateBook } = useBooksService();
  const { bookId } = useParams<RouteParams>();
  const { book, calves, organizationData } = useBookDetail(bookId);

  // const onDeleteBook = (bookId: string) => {
  //   presentAlert({
  //     header: "Are you sure you want to delete the book?",
  //     buttons: [
  //       { text: "No", role: "cancel", handler: () => {} },
  //       {
  //         text: "Yes",
  //         role: "confirm",
  //         handler: () => {
  //           updateBook(bookId, { status: "deleted" });
  //           navigate.push(`/books`, "none");
  //         },
  //       },
  //     ],
  //   });
  // };

  return (
    <IonPage>
      <Header
        title={`${book?.name || ""}`}
        onGoBack={() => navigate.push(`/books`, "none")}
        rightComponent={
          <div style={{ display: "flex" }}>
            <span className={"CalvesCount"}>{`${calves.length} Calves`}</span>
            {/*<IonButton*/}
            {/*  size={"small"}*/}
            {/*  fill={"clear"}*/}
            {/*  onClick={() => {*/}
            {/*    onDeleteBook(bookId);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <IonIcon src={"assets/trash.svg"} color={"danger"} />*/}
            {/*</IonButton>*/}
          </div>
        }
      >
        <Header.SubHeader>
          <BookToolbar />
        </Header.SubHeader>
      </Header>
      <IonContent className={"CalvesList"} scrollY={false}>
        <div>
          <Virtuoso
            data={calves}
            style={{ height: "100%" }}
            totalCount={calves.length}
            itemContent={(index, calf: Calf) => {
              return (
                <div>
                  <CalfCard
                    calf={calf}
                    bookId={bookId}
                    key={calf.id}
                    showCowName={!!organizationData?.cowName}
                    showCalfName={!!organizationData?.calfName}
                  />
                </div>
              );
            }}
          />
        </div>
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton routerLink={`/books/${bookId}/add-calf`} size={"small"}>
            <IonIcon icon={add} size={"small"}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
}

export default function Book(): React.ReactElement {
  return (
    <BookSearchProvider>
      <BookDetail />
    </BookSearchProvider>
  );
}
