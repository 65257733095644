import {
  IonCard,
  IonTitle,
  IonLabel,
  IonCardHeader,
  IonCardContent,
} from "@ionic/react";
import { Breeding } from "../../../../models";

import "../BreedingCard/styles.css";
interface BreadAttemptCardProps {
  idx: number;
  breedAttempt: Breeding;
  onCardClicked: () => void;
}
export default function BreedAttemptCard({
  idx,
  breedAttempt,
  onCardClicked,
}: BreadAttemptCardProps): React.ReactElement {
  return (
    <IonCard onClick={onCardClicked}>
      <IonCardHeader>
        <IonTitle>{`Service ${idx}`}</IonTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonLabel>
          Attempt: <span>{breedAttempt.attemptDate}</span>
        </IonLabel>

        <IonLabel>
          AI Tech: <span>{breedAttempt.aiTech}</span>
        </IonLabel>
      </IonCardContent>
    </IonCard>
  );
}
