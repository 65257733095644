import {
  IonItem,
  IonList,
  IonModal,
  IonLabel,
  IonButton,
  IonToggle,
  IonContent,
} from "@ionic/react";
import { useRef, useState } from "react";

import { Calf, Vaccine } from "../../../../models";
import useCalvesService from "../../../../services/useCalvesService";
import VaccineForm from "../../../../components/Vaccines/VaccineForm";
import useVaccinesService from "../../../../services/useVaccinesService";
import Header from "../../../../components/Header";

interface MoreModalProps {
  calf: Calf;
  isOpen: boolean;
  close: () => void;
}

export default function MoreModal({
  calf,
  close,
  isOpen,
}: MoreModalProps): React.ReactElement {
  const { updateCalf } = useCalvesService();
  const { addVaccine } = useVaccinesService();
  const modal = useRef<HTMLIonModalElement>(null);
  const [isDead, setIsDead] = useState(calf.isDead);
  const [isCull, setIsCull] = useState(calf.isCull);
  const [isPairout, setIsPairout] = useState(calf.isPairout);
  const [showVaccineForm, setShowVaccineForm] = useState(false);

  const quickUpdate = (key: string, value: boolean) => {
    updateCalf(calf.id, {
      [key]: value,
    });
  };

  const onAddVaccine = (data: Vaccine) => {
    addVaccine({
      ...data,
      calfId: calf.id,
    });

    setShowVaccineForm(false);
  };

  return (
    <IonModal isOpen={isOpen} ref={modal} onWillDismiss={(e) => close()}>
      <Header
        title={"More"}
        withBorder={false}
        rightComponent={
          <IonButton
            size={"small"}
            fill={"clear"}
            className="ion-text-uppercase ion-no-margin ion-no-padding"
            onClick={() => {
              modal.current?.dismiss();
              setShowVaccineForm(false);
            }}
          >
            Close
          </IonButton>
        }
      />
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel style={{ color: "#D41924" }}>Dead</IonLabel>
            <IonToggle
              checked={isDead}
              onIonChange={(e) => {
                setIsDead(e.detail.checked);
                quickUpdate("isDead", e.detail.checked);
              }}
            />
            <div
              style={{
                height: 30,
                width: 30,
                backgroundColor: "#D41924",
                marginLeft: 10,
                borderRadius: 100,
              }}
            />
          </IonItem>

          <IonItem>
            <IonLabel style={{ color: "#47E0E8" }}>Pairout</IonLabel>
            <IonToggle
              checked={isPairout}
              onIonChange={(e) => {
                setIsPairout(e.detail.checked);
                quickUpdate("isPairout", e.detail.checked);
              }}
            />
            <div
              style={{
                height: 30,
                width: 30,
                backgroundColor: "#47E0E8",
                marginLeft: 10,
                borderRadius: 100,
              }}
            />
          </IonItem>

          <IonItem>
            <IonLabel style={{ color: "#F9B32F" }}>Cull</IonLabel>
            <IonToggle
              checked={isCull}
              onIonChange={(e) => {
                setIsCull(e.detail.checked);
                quickUpdate("isCull", e.detail.checked);
              }}
            />
            <div
              style={{
                height: 30,
                width: 30,
                backgroundColor: "#F9B32F",
                marginLeft: 10,
                borderRadius: 100,
              }}
            />
          </IonItem>
        </IonList>
        {!showVaccineForm && (
          <IonButton onClick={() => setShowVaccineForm(true)} size="small">
            Add Vaccine
          </IonButton>
        )}
        {showVaccineForm && (
          <VaccineForm
            title=""
            backLink=""
            quickViewForm
            onFormSubmit={onAddVaccine}
          />
        )}
      </IonContent>
    </IonModal>
  );
}
