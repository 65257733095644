import { useEffect, useState } from "react";
import { IonPage } from "@ionic/react";
import { useParams } from "react-router";

import { Calf } from "../../../models";
import BornCalvesSummary from "../../../components/CalvesSummary";
import useCowsService from "../../../services/useCowsService";

type RouteParams = { cowId: string };

export default function CowSummary(): React.ReactElement {
  const { cowId } = useParams<RouteParams>();
  const { getCowCalvesSub } = useCowsService();
  const [calves, setCalves] = useState<Calf[]>([]);

  useEffect(() => {
    getCowCalvesSub(cowId, setCalves);
  }, [cowId, getCowCalvesSub]);

  return (
    <IonPage>
      <BornCalvesSummary calves={calves} navigateBackTo={`/cows`} />
    </IonPage>
  );
}
