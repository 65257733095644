import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Tab, Tabs } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";

import Form from "./Form";
import { Calf } from "../../../models";
import WeaningForm from "./WeaningForm";
import Header from "../../../components/Header";
import { useGoBack } from "../../../hooks/useGoBack";
import Segments from "../../../components/Segments";
import Vaccines from "../../../components/Vaccines";
import { useColors } from "../../../hooks/useColors";
import useCalvesService from "../../../services/useCalvesService";

interface CalfFormProps {
  title: string;
  calf?: Partial<Calf>;
  onFormSubmit: (data: Calf, twinData?: Calf) => void;
}

type RouteParams = { bookId: string };

export default function CalfForm({
  calf,
  title,
  onFormSubmit,
}: CalfFormProps): React.ReactElement {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const { insertNewColor } = useColors();
  const { bookId } = useParams<RouteParams>();
  const { getCalfByNameAndBook, deleteCalf } = useCalvesService();
  const [currentSegment, setCurrentSegment] = useState<string>("general");
  const [selectedGender, setSelectedGender] = useState(calf?.gender || "");

  const formApi = useForm<Calf>({
    defaultValues: {
      cowName: calf?.cowName || "",
      calfName: calf?.calfName || "",
      bookId: calf?.bookId || bookId,
      dateOfBirth: calf?.dateOfBirth || moment().format("MM/DD/YYYY"),
      gender: calf?.gender || undefined,
      weight: calf?.weight || 0,
      comment: calf?.comment || "",
      cowId: calf?.cowId || "",
      sireId: calf?.sireId || "",
      color: calf?.color || "",
      tagColor: calf?.tagColor || "",
      isDead: calf?.isDead || false,
      isPairout: calf?.isPairout || false,
      isCull: calf?.isCull || false,
      adoptedBy: calf?.adoptedBy || "",
      calvinEase: calf?.calvinEase || "",
      udderGrade: calf?.udderGrade || "",
      isTwin: calf?.isTwin || false,
      weaningDate: calf?.weaningDate,
      weaningWeight: calf?.weaningWeight,
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isValid, isDirty },
  } = formApi;

  useEffect(() => {
    reset(calf);
    setSelectedGender(calf?.gender || "");
  }, [calf, reset]);

  const twinFormApi = useForm<Calf>({
    defaultValues: {
      calfName: "",
      weight: 0,
      color: "",
      tagColor: "",
      gender: undefined,
    },
  });

  const goBack = useGoBack({
    guardValue: isDirty,
    navigateTo: `/books/${bookId}`,
    routerDirection: "back",
    onOkGuardHandler: () => {
      reset();
    },
  });

  const onSubmit = async (data: Calf) => {
    const twinData = twinFormApi.getValues();
    const calfExist = await getCalfByNameAndBook(data.calfName, bookId);

    if (calf && twinData.calfName) {
      presentAlert({
        header:
          "This will create another calf. Are you sure you want to continue?",
        buttons: [
          {
            text: "No",
            role: "cancel",
            handler: () => {},
          },
          {
            text: "Yes",
            role: "confirm",
            handler: () => {
              onFormSubmit(data, twinData);
              calf ? reset(data) : reset();
              twinFormApi.reset();
              //setSelectedGender("");
            },
          },
        ],
      });

      return;
    }

    if (!calf && calfExist) {
      presentAlert({
        header:
          "There is already a calf with this name for this book. Do you wanna continue?",
        buttons: [
          { text: "No", role: "cancel", handler: () => {} },
          {
            text: "Yes",
            role: "confirm",
            handler: () => {
              onFormSubmit(data, twinData);
              calf ? reset(data) : reset();
              twinFormApi.reset();
              //setSelectedGender("");
            },
          },
        ],
      });
    } else {
      onFormSubmit(data, twinData);
      calf ? reset(data) : reset();
      twinFormApi.reset();
      //setSelectedGender("");
    }

    insertNewColor(data.color);
  };

  const onDeleteCalf = (calfId: string) => {
    presentAlert({
      header: "Are you sure you want to delete the calf?",
      buttons: [
        { text: "No", role: "cancel", handler: () => {} },
        {
          text: "Yes",
          role: "confirm",
          handler: () => {
            deleteCalf(calfId);
            reset();
            twinFormApi.reset();
            router.push(`/books/${bookId}`, "none");
          },
        },
      ],
    });
  };

  return (
    <>
      <Header
        title={title}
        onGoBack={goBack}
        rightComponent={
          <>
            {currentSegment !== "vaccine" && (
              <IonButtons slot="end">
                {calf && (
                  <IonButton
                    size={"small"}
                    fill={"clear"}
                    onClick={() => {
                      onDeleteCalf(calf.id!);
                    }}
                  >
                    <IonIcon src={"assets/trash.svg"} color={"danger"} />
                  </IonButton>
                )}
                <div style={{ width: 15 }} />
                <IonButton
                  fill="clear"
                  type="submit"
                  disabled={!isValid}
                  style={{ color: "#4888AE" }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </IonButton>
              </IonButtons>
            )}
          </>
        }
      />
      <IonContent>
        {calf && (
          <Tabs
            variant={"fullWidth"}
            value={currentSegment}
            onChange={(e, value) => {
              setCurrentSegment(value);
              if (value === "weaning") {
                formApi.setValue("weaningDate", moment().format("MM/DD/YYYY"));
              }
            }}
          >
            <Tab label={"General Info"} value={"general"} />
            <Tab label={"Weaning Records"} value={"weaning"} />
            <Tab label={"Vaccines"} value={"vaccine"} />
          </Tabs>
        )}
        <Segments
          currentSegment={currentSegment}
          segments={[
            {
              id: "general",
              render: () => (
                <FormProvider {...formApi}>
                  <Form
                    isNew={!calf}
                    twinFormApi={twinFormApi}
                    selectedGender={selectedGender}
                    setSelectedGender={setSelectedGender}
                  />
                </FormProvider>
              ),
            },
            {
              id: "vaccine",
              render: () => (
                <Vaccines
                  searchKey="calfId"
                  searchId={calf?.id as string}
                  mainUrl={`books/${bookId}/calves/${calf?.id}`}
                />
              ),
            },
            {
              id: "weaning",
              render: () => (
                <FormProvider {...formApi}>
                  <WeaningForm />
                </FormProvider>
              ),
            },
          ]}
        />
      </IonContent>
    </>
  );
}
