type Segment = {
  id: string;
  render: () => React.ReactNode;
};

interface SegmentsProps {
  segments: Segment[];
  currentSegment: string;
}

export default function Segments({
  segments,
  currentSegment,
}: SegmentsProps): React.ReactElement {
  const displaySegment = segments.find(
    (segment) => segment.id === currentSegment
  );

  return <>{displaySegment && displaySegment.render()}</>;
}
