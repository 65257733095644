import {
  IonButton,
  IonButtons,
  IonContent,
  IonSegment,
  IonToolbar,
  IonSegmentButton,
  IonIcon,
  useIonRouter,
  useIonAlert,
} from "@ionic/react";
import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";

import "./styles.css";

import Form from "./Form";
import { Cow } from "../../../models";
import Header from "../../../components/Header";
import Segments from "../../../components/Segments";
import Vaccines from "../../../components/Vaccines";
import { useGoBack } from "../../../hooks/useGoBack";
import { useLocations } from "../../../hooks/useLocations";
import useCowsService from "../../../services/useCowsService";
import Breeding from "../Breeding";

interface CowFormProps {
  title: string;
  cow?: Partial<Cow>;
  onFormSubmit: (data: Cow) => void;
}

export default function CowForm({
  cow,
  title,
  onFormSubmit,
}: CowFormProps): React.ReactElement {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const { deleteCow } = useCowsService();
  const { insertNewLocation } = useLocations();
  const [currentSegment, setCurrentSegment] = useState<string>("general");
  const formApi = useForm<Cow>({
    defaultValues: {
      cowName: cow?.cowName || "",
      location: cow?.location || "",
      purchasePrice: cow?.purchasePrice || 0,
      dateOfPurchase: cow?.dateOfPurchase || undefined,
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isValid, isDirty },
  } = formApi;

  const goBack = useGoBack({
    guardValue: isDirty,
    navigateTo: "/cows",
    routerDirection: "back",
    onOkGuardHandler: () => {
      reset();
    },
  });

  const onSubmit = (data: Cow) => {
    onFormSubmit(data);
    reset(data);
    insertNewLocation(data.location);
  };

  const onDeleteCow = (cowId: string) => {
    presentAlert({
      header: "Are you sure you want to delete the cow?",
      buttons: [
        { text: "No", role: "cancel", handler: () => {} },
        {
          text: "Yes",
          role: "confirm",
          handler: () => {
            deleteCow(cowId);
            router.push(`/cows`, "none");
          },
        },
      ],
    });
  };

  return (
    <>
      <Header
        title={title}
        onGoBack={goBack}
        rightComponent={
          <>
            {currentSegment === "general" && (
              <IonButtons slot="end">
                {cow && (
                  <IonButton
                    size={"small"}
                    fill={"clear"}
                    onClick={() => {
                      onDeleteCow(cow.id!);
                    }}
                  >
                    <IonIcon src={"assets/trash.svg"} color={"danger"} />
                  </IonButton>
                )}
                <div style={{ width: 15 }} />
                <IonButton
                  fill="clear"
                  type="submit"
                  disabled={!isValid}
                  style={{ color: "#4888AE" }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </IonButton>
              </IonButtons>
            )}
          </>
        }
      />
      <IonContent>
        {cow && (
          <IonToolbar>
            <IonSegment
              value={currentSegment}
              disabled={isDirty}
              onIonChange={(e) => setCurrentSegment(e.detail.value as string)}
            >
              <IonSegmentButton value="general">General Info</IonSegmentButton>
              <IonSegmentButton value="vaccine">Vaccines</IonSegmentButton>
              <IonSegmentButton value="breeding">
                Breeding & Preg
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        )}
        <Segments
          currentSegment={currentSegment}
          segments={[
            {
              id: "general",
              render: () => (
                <FormProvider {...formApi}>
                  <Form onFormSubmit={onSubmit} />
                </FormProvider>
              ),
            },
            {
              id: "vaccine",
              render: () => (
                <Vaccines
                  searchKey="cowId"
                  mainUrl={`cows/${cow?.id}`}
                  searchId={cow?.id as string}
                />
              ),
            },
            {
              id: "breeding",
              render: () => <Breeding cowId={cow?.id!} />,
            },
          ]}
        />
      </IonContent>
    </>
  );
}
