import { add } from "ionicons/icons";
import { useEffect, useState } from "react";
import { IonFab, IonFabButton, IonIcon } from "@ionic/react";

import { BreedingRecord } from "../../../models/breeding";
import BreedingRecordCard from "./BreedingCard/BreedingRecordCard";
import useBreedingService from "../../../services/useBreedingService";

interface BreedingProps {
  cowId: string;
}
export default function BreedingList({
  cowId,
}: BreedingProps): React.ReactElement {
  const { getBreedingsSub } = useBreedingService();
  const [breedingRecords, setBreedingRecords] = useState<BreedingRecord[]>([]);

  useEffect(() => {
    getBreedingsSub(cowId, setBreedingRecords);
  }, [cowId, getBreedingsSub]);

  return (
    <>
      {breedingRecords.map((breedingRecord) => (
        <BreedingRecordCard
          key={breedingRecord.id}
          breedingRecord={breedingRecord}
        />
      ))}
      <IonFab slot="fixed" vertical="bottom" horizontal="end">
        <IonFabButton routerLink={`/cows/${cowId}/add-breeding`}>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
    </>
  );
}
