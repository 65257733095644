import {
  IonButton,
  IonCard,
  IonIcon,
  IonLabel,
  useIonRouter,
} from "@ionic/react";
import { useState } from "react";
import { ellipsisHorizontalOutline } from "ionicons/icons";

import "./styles.css";

import MoreModal from "./MoreModal";
import { Sire } from "../../../../models";

interface SireCardProps {
  sire: Sire;
}

const SireCard = ({ sire }: SireCardProps) => {
  const router = useIonRouter();
  const [isMoreOpen, setIsMoreOpen] = useState(false);

  const onCardClicked = (e: any) => {
    if (e.target.id === "more") {
      return setIsMoreOpen(true);
    }

    router.push(`/sires/${sire.id}`, "forward");
  };

  return (
    <div>
      <IonCard className="SireCard" onClick={(e) => onCardClicked(e)}>
        <div className={"SireCardData"}>
          <h4>{sire.sireName}</h4>
          <IonLabel>{sire.location || "-"}</IonLabel>
          <IonLabel>{sire.dateOfPurchase || ""}</IonLabel>
        </div>
        <IonButton fill="clear" id="more" className="SireMore" size={"small"}>
          <IonIcon icon={ellipsisHorizontalOutline} size="small" />
        </IonButton>
      </IonCard>
      <MoreModal
        sireId={sire.id}
        isOpen={isMoreOpen}
        close={() => setIsMoreOpen(false)}
      />
    </div>
  );
};

export default SireCard;
