import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";

import { Calf } from "../models";

interface BornCalvesSummaryProps {
  calves: Calf[];
  navigateBackTo: string;
}

const BornCalvesSummary = ({
  calves,
  navigateBackTo,
}: BornCalvesSummaryProps) => {
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              fill="clear"
              routerLink={navigateBackTo}
              routerDirection="back"
            >
              <IonIcon icon={chevronBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>{`Borned Calves: ${calves.length}`}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {calves.map((calf) => (
            <IonItem key={calf.id}>{calf.calfName}</IonItem>
          ))}
        </IonList>
      </IonContent>
    </>
  );
};

export default BornCalvesSummary;
