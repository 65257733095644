import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { add } from "ionicons/icons";

import VaccineCard from "./VaccineCard";
import { useVaccines } from "./useVaccines";

interface VaccinesProps {
  mainUrl: string;
  searchKey: "cowId" | "calfId";
  searchId: string;
}

export default function Vaccines({
  mainUrl,
  searchKey,
  searchId,
}: VaccinesProps): React.ReactElement {
  const { vaccines } = useVaccines(searchKey, searchId);

  return (
    <>
      {vaccines.map((vaccine) => (
        <VaccineCard
          key={vaccine.id}
          vaccine={vaccine}
          vaccineUrl={`/${mainUrl}/vaccines/${vaccine.id}`}
        />
      ))}
      <IonFab slot="fixed" vertical="bottom" horizontal="end">
        <IonFabButton routerLink={`/${mainUrl}/add-vaccine`}>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
    </>
  );
}
