import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { IonPage, useIonRouter } from "@ionic/react";

import CalfForm from "../CalfForm";
import { Calf } from "../../../models";
import useCowsService from "../../../services/useCowsService";
import useCalvesService from "../../../services/useCalvesService";

type RouteParams = { calfId: string; bookId: string };

export default function EditCalf(): React.ReactElement {
  const router = useIonRouter();
  const { upsertCow } = useCowsService();
  const [calf, setCalf] = useState<Calf>();
  const { calfId, bookId } = useParams<RouteParams>();
  const { getCalfSub, addCalf, updateCalf } = useCalvesService();

  useEffect(() => {
    getCalfSub(calfId, setCalf);
  }, [calfId, getCalfSub]);

  const onEditCalf = async (data: Calf, twinData?: Calf) => {
    let twinId = undefined;
    if (twinData?.calfName) twinId = uuidv4();
    upsertCow(data.cowName as string);
    updateCalf(calfId, {
      twinId,
      ...data,
      isTwin: data.isTwin || !!twinId,
      dateOfBirth: data.dateOfBirth || "",
    });

    if (twinId) {
      addCalf(twinId, {
        ...(twinData as Calf),
        dateOfBirth: data.dateOfBirth || "",
        gender: data.gender,
        sireId: data.sireId,
        cowId: data.cowId,
        cowName: data.cowName,
        adoptedBy: data.adoptedBy,
        twinId: calfId,
        isTwin: true,
        bookId,
        comment: data.comment,
        calvinEase: data.calvinEase,
        udderGrade: data.udderGrade,
      });
    }

    router.push(`/books/${bookId}`, "none");
  };

  return (
    <IonPage>
      {calf && (
        <CalfForm
          calf={calf}
          title={`Edit ${calf.calfName}`}
          onFormSubmit={onEditCalf}
        />
      )}
    </IonPage>
  );
}
