import { useCallback } from "react";
import {
  doc,
  query,
  where,
  addDoc,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  onSnapshot,
  collection,
  documentId,
} from "firebase/firestore";

import db from "../db/firebase";
import { Vaccine } from "../models";
import { getCurrentTimestamp } from "../utils";
import { useAuth } from "../Auth/AuthProvider";
import { vaccineConverter } from "../db/converters";

export default function useVaccinesService() {
  const { currentUser } = useAuth();
  const orgId = currentUser?.uid!;

  const getVaccine = useCallback(
    async (vaccineId: string): Promise<Vaccine | undefined> => {
      const vaccineDocRef = doc(
        db,
        `organizations/${orgId}/vaccines/${vaccineId}`
      ).withConverter(vaccineConverter);
      const vaccineDoc = await getDoc(vaccineDocRef);
      return vaccineDoc.data();
    },
    [orgId]
  );

  const getBatchVaccine = useCallback(
    async (batchId: string): Promise<Vaccine | undefined> => {
      const batchDocRef = doc(
        db,
        `organizations/${orgId}/batches`,
        batchId
      ).withConverter(vaccineConverter);
      const batchDoc = await getDoc(batchDocRef);
      if (batchDoc.exists()) return batchDoc.data();
    },
    [orgId]
  );

  const normalizeVaccines = useCallback(
    async (vaccines: Vaccine[]): Promise<Vaccine[]> => {
      for (let i = 0; i < vaccines.length; i++) {
        let vaccine = vaccines[i];
        if (vaccine.isBatch) {
          const batchVaccine = await getBatchVaccine(vaccine.batchId as string);

          if (batchVaccine) {
            vaccine.shotName = batchVaccine.shotName;
            vaccine.dose = batchVaccine.dose;
            vaccine.date = batchVaccine.date;
            vaccine.comment = batchVaccine.comment;
          }
        }
      }

      return vaccines;
    },
    [getBatchVaccine]
  );

  const getVaccines = useCallback(
    async (
      searchKey: "cowId" | "calfId",
      searchId: string
    ): Promise<Vaccine[]> => {
      const vaccinesCollectionRef = query(
        collection(db, `organizations/${orgId}/vaccines`),
        where(searchKey, "==", searchId)
      ).withConverter(vaccineConverter);
      const vaccinesDocs = await getDocs(vaccinesCollectionRef);
      const vaccines = vaccinesDocs.docs.map((doc) => doc.data());
      return await normalizeVaccines(vaccines);
    },
    [orgId, normalizeVaccines]
  );

  const getVaccinesSub = useCallback(
    (
      searchKey: "cowId" | "calfId",
      searchId: string,
      cb: (vaccines: Vaccine[]) => void
    ): void => {
      const vaccinesCollectionRef = query(
        collection(db, `organizations/${orgId}/vaccines`),
        where(searchKey, "==", searchId)
      ).withConverter(vaccineConverter);
      onSnapshot(vaccinesCollectionRef, async (snapshot) => {
        const vaccines = snapshot.docs.map((doc) => doc.data());
        const normalizedVaccines = await normalizeVaccines(vaccines);
        cb(normalizedVaccines);
      });
    },
    [orgId, normalizeVaccines]
  );

  const addVaccine = useCallback(
    (vaccine: Vaccine): void => {
      addDoc(
        collection(db, `organizations/${orgId}/vaccines`).withConverter(
          vaccineConverter
        ),
        {
          ...vaccine,
          createdAt: getCurrentTimestamp(),
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const updateVaccine = useCallback(
    (vaccineId: string, vaccine: Vaccine): void => {
      updateDoc(
        doc(db, `organizations/${orgId}/vaccines`, vaccineId).withConverter(
          vaccineConverter
        ),
        { ...vaccine, updatedAt: getCurrentTimestamp() }
      );
    },
    [orgId]
  );

  const getBatches = useCallback(
    async (batchType: string, bookId?: string): Promise<Vaccine[]> => {
      const queryWhereClause = bookId
        ? [
            where(batchType, "==", true),
            where("booksIds", "array-contains", bookId),
          ]
        : [where(batchType, "==", true)];

      const batchesCollectionRef = query(
        collection(db, `organizations/${orgId}/batches`),
        ...queryWhereClause
      ).withConverter(vaccineConverter);

      const batchesDocs = await getDocs(batchesCollectionRef);
      const batches = batchesDocs.docs.map((doc) => doc.data());
      return await normalizeVaccines(batches);
    },
    [orgId, normalizeVaccines]
  );

  const addBatchVaccine = useCallback(
    (vaccineId: string, vaccine: Vaccine): void => {
      setDoc(
        doc(db, `organizations/${orgId}/batches`, vaccineId).withConverter(
          vaccineConverter
        ),
        {
          ...vaccine,
          createdAt: getCurrentTimestamp(),
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const updateBatchVaccine = useCallback(
    (vaccineId: string, vaccine: Vaccine): void => {
      updateDoc(
        doc(db, `organizations/${orgId}/batches`, vaccineId).withConverter(
          vaccineConverter
        ),
        { ...vaccine, updatedAt: getCurrentTimestamp() }
      );
    },
    [orgId]
  );

  const addBatchToCattle = useCallback(
    async (
      vaccineId: string,
      excludedCattle: string[],
      cattleCollection: string,
      cattleKey: "cowId" | "calfId"
    ): Promise<void> => {
      const queryWhereClause =
        excludedCattle.length > 0
          ? [where(documentId(), "not-in", excludedCattle)]
          : [];
      const cattleCollectionRef = query(
        collection(db, `organizations/${orgId}/${cattleCollection}`),
        ...queryWhereClause
      );

      const cattle = await getDocs(cattleCollectionRef);

      cattle.docs.forEach((cattle) => {
        const cattleId = cattle.id;
        addDoc(collection(db, `organizations/${orgId}/vaccines`), {
          isBatch: true,
          batchId: vaccineId,
          [cattleKey]: cattleId,
        });
      });
    },
    [orgId]
  );

  return {
    getBatches,
    addVaccine,
    getVaccine,
    getVaccines,
    updateVaccine,
    getVaccinesSub,
    getBatchVaccine,
    addBatchVaccine,
    addBatchToCattle,
    updateBatchVaccine,
  };
}
