import { Moment } from "moment/moment";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { IonCard, IonSelect, IonSelectOption, IonToggle } from "@ionic/react";

import FormControl from "../../../../components/FormControl/FormControl";

export default function RecordForm(): React.ReactElement {
  const formApi = useFormContext();
  const { watch, control, register, setValue, getValues } = formApi;

  const twins = watch("twins");

  return (
    <form>
      <IonCard>
        <FormControl label={"Pregnant"}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <IonToggle
              {...register("pregnant")}
              onIonChange={(e) => {
                setValue("pregnant", e.detail.checked);
              }}
              checked={getValues("pregnant")}
            />
          </div>
        </FormControl>
        <FormControl label={"Expected Date Birth"}>
          <Controller
            name="expectedDateBirth"
            control={control}
            render={({ field }) => (
              <MobileDatePicker
                closeOnSelect
                value={field.value || null}
                inputFormat="MM/DD/YYYY"
                onChange={(e: Moment | null) => {
                  setValue("expectedDateBirth", e?.format("MM/DD/YYYY") || "", {
                    shouldDirty: true,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size={"small"}
                    fullWidth
                    style={{ marginTop: 10 }}
                  />
                )}
              />
            )}
          />
        </FormControl>
        <FormControl label={"Gender"}>
          <Controller
            control={control}
            render={({ field }) => (
              <IonSelect
                placeholder="Select"
                value={getValues().twins ? "Twin" : field.value}
                onIonChange={(e) => {
                  setValue("gender", e.target.value);
                  setValue("twins", e.target.value === "Twin");
                }}
              >
                {["Bull", "Heifer", "Steer", "Twin"].map((gender) => (
                  <IonSelectOption key={gender} value={gender}>
                    {gender}
                  </IonSelectOption>
                ))}
              </IonSelect>
            )}
            name={"gender"}
          />
        </FormControl>
        {twins && (
          <FormControl label={"Twins Gender"}>
            <Controller
              control={control}
              render={({ field }) => (
                <IonSelect
                  placeholder="Select"
                  value={field.value}
                  onIonChange={(e) => {
                    setValue("gender", e.target.value);
                  }}
                >
                  {["Bull", "Heifer", "Steer"].map((gender) => (
                    <IonSelectOption key={gender} value={gender}>
                      {gender}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              )}
              name={"gender"}
            />
          </FormControl>
        )}
      </IonCard>
    </form>
  );
}
