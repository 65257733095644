import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  isPlatform,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { Redirect, Route } from "react-router";
import { collection, query, getDocs } from "firebase/firestore";

import db from "../db/firebase";
import { useAuth } from "./AuthProvider";
import { Profile } from "../pages/Users";
import { EditProfile } from "../pages/Users/EditProfile";

import {
  AddCow,
  EditCow,
  CowsList,
  CowSummary,
  AddCowVaccine,
  EditCowVaccine,
  CowsBatchesList,
  AddCowBatchVaccine,
} from "../pages/Cows";

import {
  AddBook,
  EditBook,
  BooksList,
  BookBatchesList,
  Book as BookDetail,
  AddBooksBatchVaccine,
} from "../pages/Books";

import {
  AddCalf,
  AddCalfVaccine,
  EditCalf,
  EditCalfVaccine,
} from "../pages/Calves";

import { AddSire, EditSire, SiresList, SireSummary } from "../pages/Sires";

import { AddNote, EditNote, NotesList } from "../pages/Notes";

import { EditBatch } from "../pages/Batches";

import BookReport from "../pages/Books/BookReport";
import BookSummary from "../pages/Books/BookSummary";
import LoadingScreen from "../components/LoadingScreen";
import BreedingRecordForm from "../pages/Cows/Breeding/BreedingRecordForm";
import BreedAttemptsList from "../pages/Cows/Breeding/BreedAttemptsList";

export const TrialRoutes = () => {
  return (
    <IonRouterOutlet>
      <Route exact path="/profile" component={Profile} />
      <Route exact path={"/profile/edit"} component={EditProfile} />
      <Route render={() => <Redirect to={"/profile"} />} />
    </IonRouterOutlet>
  );
};
export const AuthenticatedRoutes = () => {
  const auth = useAuth();
  const matches = useMediaQuery("(min-width: 1280px)");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchIsAuthed() {
      const isDesktop = isPlatform("desktop");
      const currentUser = auth.currentUser;

      if (currentUser && !isDesktop) {
        const orgId = currentUser.uid;

        const cowsColRef = query(collection(db, `organizations/${orgId}/cows`));
        const siresColRef = query(
          collection(db, `organizations/${orgId}/sires`)
        );
        const booksColRef = query(
          collection(db, `organizations/${orgId}/books`)
        );
        const calvesColRef = query(
          collection(db, `organizations/${orgId}/calves`)
        );
        const vaccinesColRef = query(
          collection(db, `organizations/${orgId}/vaccines`)
        );
        const colorsColRef = query(
          collection(db, `organizations/${orgId}/refColors`)
        );
        const locationsColRef = query(
          collection(db, `organizations/${orgId}/refLocations`)
        );
        const batchesColRef = query(
          collection(db, `organizations/${orgId}/batches`)
        );

        await getDocs(cowsColRef);
        await getDocs(siresColRef);
        await getDocs(booksColRef);
        await getDocs(calvesColRef);
        await getDocs(colorsColRef);
        await getDocs(batchesColRef);
        await getDocs(vaccinesColRef);
        await getDocs(locationsColRef);
      }

      setIsLoading(false);
    }
    fetchIsAuthed();
  }, [auth]);
  if (!isLoading && !auth.currentUser) {
    return <Redirect to={"/login"} />;
  }

  if (isLoading)
    return (
      <LoadingScreen
        message={
          "Please be patient while we load all your data to the app for offline use."
        }
      />
    );
  return auth.accountStatus === "unsubscribed" ? (
    <TrialRoutes />
  ) : (
    <>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/" render={() => <Redirect to={"/books"} />} />
          <Route
            exact
            path="/login"
            render={() => <Redirect to={"/books"} />}
          />
          <Route exact path="/books" component={BooksList} />
          <Route exact path="/add-book" component={AddBook} />
          <Route exact path="/books/:bookId" component={BookDetail} />
          <Route exact path="/books/:bookId/add-calf" component={AddCalf} />
          <Route exact path="/books/:bookId/edit-book" component={EditBook} />
          <Route exact path="/books/:bookId/summary" component={BookSummary} />
          <Route exact path="/books/:bookId/report" component={BookReport} />
          <Route
            exact
            path="/books/:bookId/calves/:calfId"
            component={EditCalf}
          />
          <Route
            exact
            path="/books/:bookId/calves/:calfId/add-vaccine"
            component={AddCalfVaccine}
          />
          <Route
            exact
            path="/books/:bookId/calves/:calfId/vaccines/:vaccineId"
            component={EditCalfVaccine}
          />
          <Route
            exact
            path="/books/add-batch-vaccine"
            component={AddBooksBatchVaccine}
          />
          <Route
            exact
            path="/books/:bookId/batches"
            component={BookBatchesList}
          />
          <Route exact path="/cows" component={CowsList} />
          <Route exact path="/add-cow" component={AddCow} />
          <Route exact path="/cows/:cowId" component={EditCow} />
          <Route exact path="/cows/:cowId/summary" component={CowSummary} />
          <Route
            exact
            path="/cows/:cowId/add-vaccine"
            component={AddCowVaccine}
          />
          <Route
            exact
            path="/cows/:cowId/add-breeding"
            component={BreedingRecordForm}
          />
          <Route
            exact
            path="/cows/:cowId/breedings/:breedId"
            component={BreedAttemptsList}
          />
          <Route
            exact
            path="/cows/:cowId/vaccines/:vaccineId"
            component={EditCowVaccine}
          />
          <Route
            exact
            path="/cows/add-batch-vaccine"
            component={AddCowBatchVaccine}
          />
          <Route exact path="/cows/batches" component={CowsBatchesList} />
          <Route exact path="/sires" component={SiresList} />
          <Route exact path="/add-sire" component={AddSire} />
          <Route exact path="/sires/:sireId" component={EditSire} />
          <Route exact path="/sires/:sireId/summary" component={SireSummary} />
          {/* <Route exact path="/batches" component={BatchList} /> */}
          <Route exact path="/batches/:batchId" component={EditBatch} />
          <Route exact path="/notes" component={NotesList} />
          <Route exact path="/add-note" component={AddNote} />
          <Route exact path="/notes/:noteId" component={EditNote} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path={"/profile/edit"} component={EditProfile} />
        </IonRouterOutlet>
        <IonTabBar slot={matches ? "top" : "bottom"}>
          <IonTabButton tab="books" href="/books">
            <IonIcon src={"assets/calf.svg"} />
            <IonLabel>Calves</IonLabel>
          </IonTabButton>
          <IonTabButton tab="cows" href="/cows">
            <IonIcon src={"assets/cow.svg"} />
            <IonLabel>Cows</IonLabel>
          </IonTabButton>
          <IonTabButton tab="sires" href="/sires">
            <IonIcon src={"assets/sire.svg"} />
            <IonLabel>Sires</IonLabel>
          </IonTabButton>
          <IonTabButton tab="notes" href="/notes">
            <IonIcon src={"assets/note.svg"} />
            <IonLabel>Notes</IonLabel>
          </IonTabButton>
          <IonTabButton tab="profile" href="/profile">
            <IonIcon src={"assets/profile.svg"} />
            <IonLabel>Profile</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  );
};
