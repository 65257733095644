import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { IonPage, useIonRouter } from "@ionic/react";

import { Vaccine } from "../../../models";
import VaccineForm from "../../../components/Vaccines/VaccineForm";
import useVaccinesService from "../../../services/useVaccinesService";

type RouteParams = { batchId: string };

export default function EditBatch(): React.ReactElement {
  const router = useIonRouter();
  const { batchId } = useParams<RouteParams>();
  const [batch, setBatch] = useState<Vaccine>();
  const { getBatchVaccine, updateBatchVaccine } = useVaccinesService();

  useEffect(() => {
    async function fetchBatch() {
      const batch = await getBatchVaccine(batchId);
      setBatch(batch);
    }

    fetchBatch();
  }, [batchId, getBatchVaccine]);

  const onEditBatch = async (data: Vaccine) => {
    updateBatchVaccine(batchId, data);
    router.push("/books");
  };

  return (
    <IonPage>
      {batch && (
        <VaccineForm
          vaccine={batch}
          title="Edit Batch"
          backLink="/books"
          onFormSubmit={onEditBatch}
        />
      )}
    </IonPage>
  );
}
