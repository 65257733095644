import { Moment } from "moment/moment";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { IonCard, IonInput, IonSelect, IonSelectOption } from "@ionic/react";

import { useBreedingForm } from "./useBreedingForm";
import FormControl from "../../../../components/FormControl/FormControl";

export default function Form(): React.ReactElement {
  const formApi = useFormContext();
  const { sires } = useBreedingForm();
  const { control, register, setValue } = formApi;

  return (
    <form>
      <IonCard>
        <FormControl label={"Attempt Date"}>
          <Controller
            name="attemptDate"
            control={control}
            render={({ field }) => (
              <MobileDatePicker
                closeOnSelect
                value={field.value || null}
                inputFormat="MM/DD/YYYY"
                onChange={(e: Moment | null) => {
                  setValue("attemptDate", e?.format("MM/DD/YYYY") || "", {
                    shouldDirty: true,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size={"small"}
                    fullWidth
                    style={{ marginTop: 10 }}
                  />
                )}
              />
            )}
          />
        </FormControl>
        <FormControl label={"Sire ID"}>
          <Controller
            name="sireId"
            control={control}
            render={({ field }) => (
              <IonSelect
                value={field.value}
                placeholder="Select"
                onIonChange={(e) => setValue("sireId", e.target.value)}
              >
                {sires.map((sire) => (
                  <IonSelectOption key={sire.id} value={sire.id}>
                    {sire.sireName}
                  </IonSelectOption>
                ))}
              </IonSelect>
            )}
          />
        </FormControl>
        <FormControl label={"AI Tech"}>
          <IonInput
            {...register("aiTech")}
            type={"text"}
            placeholder="AI Tech"
          />
        </FormControl>
      </IonCard>
    </form>
  );
}
