import { Controller, useFormContext } from "react-hook-form";
import { IonInput, IonSelect, IonSelectOption } from "@ionic/react";

import { Calf } from "../../../../models";
import { toUpperCase } from "../../../../utils";
import AutoComplete from "../../../../components/AutoComplete";
import FormControl from "../../../../components/FormControl/FormControl";

interface TwinFormProps {
  colors: string[];
  insertNewColor: (color: string) => void;
}

export default function TwinForm({
  colors,
  insertNewColor,
}: TwinFormProps): React.ReactElement {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<Calf>();

  return (
    <>
      <FormControl label={"Calf ID"} required error={!!errors.calfName}>
        <IonInput
          {...register("calfName", {
            required: "This is a required field",
            minLength: 1,
          })}
          placeholder="Calf ID"
          onIonChange={(e) =>
            setValue("calfName", toUpperCase(e.target.value as string), {
              shouldDirty: true,
              shouldValidate: true,
            })
          }
        />
      </FormControl>

      <FormControl label={"Gender"}>
        <Controller
          name="gender"
          control={control}
          render={({ field }) => (
            <IonSelect
              value={field.value}
              onIonChange={(e) => {
                setValue("gender", e.target.value);
              }}
            >
              {["Bull", "Heifer", "Steer"].map((gender) => (
                <IonSelectOption key={gender} value={gender}>
                  {gender}
                </IonSelectOption>
              ))}
            </IonSelect>
          )}
        />
      </FormControl>
      <FormControl label={"Weight"}>
        <IonInput
          {...register("weight", {
            valueAsNumber: true,
          })}
          type="number"
          placeholder="Weight"
          onIonChange={(e) =>
            setValue("weight", e.target.value as number, {
              shouldDirty: true,
            })
          }
        />
      </FormControl>
      <FormControl label={"Color"}>
        <Controller
          name="color"
          control={control}
          render={({ field }) => (
            <AutoComplete
              value={field.value}
              onValueChange={(value) => {
                setValue("color", value.label, { shouldDirty: true });
              }}
              onAddNewValue={(value) => {
                insertNewColor(value.label);
              }}
              inputLabel="Select or create a color"
              options={colors.map((color) => ({ label: color, id: color }))}
            />
          )}
        />
      </FormControl>
      <FormControl label={"Tag Color"}>
        <Controller
          name="tagColor"
          control={control}
          render={({ field }) => (
            <IonSelect
              placeholder="Tag Color"
              value={field.value}
              onIonChange={(e) => {
                setValue("tagColor", e.target.value);
              }}
            >
              {[
                "Black",
                "Blue",
                "Green",
                "Orange",
                "Pink",
                "Purple",
                "Red",
                "Teal",
                "White",
                "Yellow",
              ].map((tagColor) => (
                <IonSelectOption key={tagColor} value={tagColor}>
                  {tagColor}
                </IonSelectOption>
              ))}
            </IonSelect>
          )}
        />
      </FormControl>
    </>
  );
}
