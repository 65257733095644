import { createOutline, trashOutline } from "ionicons/icons";
import { IonButton, IonIcon, IonItem, IonLabel } from "@ionic/react";

import { Book } from "../../../models";

interface BooksListProps {
  books: Book[];
  selectedBooks: string[];
  onBookUnselected: (bookId: string) => void;
  onOpenExcludedCalves: (bookId: string) => void;
}

export default function BooksList({
  books,
  selectedBooks,
  onBookUnselected,
  onOpenExcludedCalves,
}: BooksListProps): React.ReactElement {
  return (
    <>
      <h6 style={{ textAlign: "center", marginInline: 10 }}>
        After adding a Book, you can exclude specific calves from the entire
        list
      </h6>
      {selectedBooks.map((bookId) => {
        const book = books.find((book) => book.id === bookId) as Book;

        if (book)
          return (
            <IonItem key={bookId}>
              <IonLabel>{book.name}</IonLabel>
              <IonButton
                fill="clear"
                size="small"
                onClick={() => onOpenExcludedCalves(bookId)}
              >
                <IonIcon slot="end" icon={createOutline} />
              </IonButton>
              <IonButton
                fill="clear"
                size="small"
                onClick={() => onBookUnselected(bookId)}
              >
                <IonIcon slot="end" icon={trashOutline} />
              </IonButton>
            </IonItem>
          );

        return null;
      })}
    </>
  );
}
