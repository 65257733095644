import React from "react";
import { createRoot } from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./Auth/AuthProvider";

const container = document.getElementById("root");
const root = createRoot(container!);

const theme = createTheme({
  palette: {
    primary: {
      main: "#4888AE",
    },
  },
});

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </LocalizationProvider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
