import { IonHeader, IonIcon, IonToolbar } from "@ionic/react";
import { chevronBack } from "ionicons/icons";

import "./styles.css";
interface HeaderProps {
  title: string;
  withBorder?: boolean;
  onGoBack?: () => void;
  rightComponent?: React.ReactNode;
  children?: React.ReactNode;
}
export default function Header({
  title,
  onGoBack,
  children,
  rightComponent,
  withBorder = true,
}: HeaderProps) {
  return (
    <IonHeader>
      <IonToolbar className={`${!withBorder ? "noBorder" : ""}`}>
        <div className={"AppHeader"}>
          <div className={"Title"}>
            {onGoBack && (
              <IonIcon
                icon={chevronBack}
                className={"ion-color-primary"}
                onClick={onGoBack}
              />
            )}
            <span>{title}</span>
          </div>
          {rightComponent && <div>{rightComponent}</div>}
        </div>
        {children}
      </IonToolbar>
    </IonHeader>
  );
}

interface SubHeaderProps {
  children: React.ReactNode;
}
function SubHeader({ children }: SubHeaderProps) {
  return <div>{children}</div>;
}

Header.SubHeader = SubHeader;
