import { useState } from "react";
import { useParams } from "react-router";
import { ellipsisVerticalOutline } from "ionicons/icons";
import {
  IonButton,
  IonIcon,
  IonSearchbar,
  useIonLoading,
  useIonToast,
} from "@ionic/react";

import "./styles.css";

import SortModal from "./SortModal";
import { useBookSearch } from "./BookSearchProvider";
import MoreModal from "../../BooksList/BookCard/MoreModal";
import { CalfReport } from "../../../../models/calf";
import useBookCalvesReport from "../../../../hooks/useBookCalvesReport";
import useExport from "../../../../hooks/useExport";

type RouteParams = { bookId: string };

export default function BookToolbar(): React.ReactElement {
  const [present] = useIonToast();
  const { bookId } = useParams<RouteParams>();
  const [presentLoader, dismiss] = useIonLoading();
  const { book, getReport } = useBookCalvesReport(bookId);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const { searchValue, onSearchValueChange } = useBookSearch();
  const { exportDataCsv, exportDataPdf, exportData } = useExport();

  const onExportBook = async () => {
    presentLoader();
    try {
      const reportData = await getReport();
      const csv = exportDataCsv<CalfReport>(reportData, [
        { label: "Cow ID", key: "cowName" },
        { label: "Calf ID", key: "calfName" },
        { label: "Weight", key: "weight" },
        { label: "Gender", key: "gender" },
        { label: "Date", key: "dateOfBirth" },
        { label: "Sire", key: "sireName" },
        { label: "Color", key: "color" },
        { label: "Calving Ease", key: "calvinEase" },
        { label: "Comment", key: "comment" },
        { label: "Udder Grade", key: "udderGrade" },
        { label: "Tag Color", key: "tagColor" },
        { label: "Dead", key: "isDead" },
        { label: "Pairout", key: "isPairout" },
        { label: "Cull", key: "isCull" },
        { label: "Weaning Weight", key: "weaningWeight" },
        { label: "Weaning Date", key: "weaningDate" },
      ]);
      const pdf = exportDataPdf<CalfReport>(
        book!.name,
        [
          { label: "Cow ID", key: "cowName", width: "auto" },
          { label: "Calf ID", key: "calfName", width: "auto" },
          { label: "Weight", key: "weight", width: "auto" },
          { label: "Gender", key: "gender", width: "auto" },
          { label: "Date", key: "dateOfBirth", width: "auto" },
          { label: "Sire", key: "sireName", width: "auto" },
          { label: "Color", key: "color", width: "auto" },
          { label: "Calving Ease", key: "calvinEase", width: "auto" },
          { label: "Comment", key: "comment", width: "auto" },
          { label: "Udder Grade", key: "udderGrade", width: "auto" },
          { label: "Tag Color", key: "tagColor", width: "auto" },
          { label: "Dead", key: "isDead", width: "auto" },
          { label: "Pairout", key: "isPairout", width: "auto" },
          { label: "Cull", key: "isCull", width: "auto" },
          { label: "Weaning Weight", key: "weaningWeight", width: "auto" },
          { label: "Weaning Date", key: "weaningDate", width: "auto" },
        ],
        reportData
      );
      await exportData(`${book!.name}`, csv, pdf);

      present({
        position: "top",
        duration: 1500,
        color: "success",
        message: "Data exported into CSV and PDF.",
      });
    } catch (err) {
      console.log(err);
      present({
        duration: 1500,
        position: "top",
        color: "danger",
        message: "Something went wrong when exporting data.",
      });
    }
    dismiss();
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <IonSearchbar
          value={searchValue}
          onIonChange={(e) => {
            onSearchValueChange(e.target.value || "");
          }}
          className="ion-padding-horizontal"
        />
        <IonButton
          fill="clear"
          className="ion-no-padding ion-no-margin"
          onClick={() => setIsSortOpen(true)}
        >
          <IonIcon src={"assets/sort.svg"} size="large" />
        </IonButton>
        <IonButton
          fill="clear"
          className="ion-no-padding ion-no-margin"
          onClick={() => setIsMoreOpen(true)}
        >
          <IonIcon icon={ellipsisVerticalOutline} size="large" />
        </IonButton>
      </div>
      <SortModal isOpen={isSortOpen} close={() => setIsSortOpen(false)} />
      {book && (
        <MoreModal
          book={book}
          isOpen={isMoreOpen}
          close={() => setIsMoreOpen(false)}
          onExportBook={onExportBook}
        />
      )}
    </>
  );
}
