import {
  query,
  where,
  collection,
  onSnapshot,
  getDocs,
  addDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { useCallback } from "react";

import db from "../db/firebase";
import { Breeding } from "../models";
import { getCurrentTimestamp } from "../utils";
import { useAuth } from "../Auth/AuthProvider";
import { BreedingRecord } from "../models/breeding";
import breedingConverter from "../db/converters/breedingConverter";

export default function useBreedingService() {
  const { currentUser } = useAuth();
  const orgId = currentUser?.uid!;

  const getBreedingSub = useCallback(
    async (
      breedId: string,
      cb: (breeding: BreedingRecord | undefined) => void
    ) => {
      const breedingDocRef = doc(
        db,
        `organizations/${orgId}/breedings`,
        breedId
      ).withConverter(breedingConverter);
      onSnapshot(breedingDocRef, (snapshot) => {
        cb(snapshot.data());
      });
    },
    [orgId]
  );

  const getBreedings = useCallback(
    async (cowId: string) => {
      const breedingsCollectionRef = query(
        collection(db, `organizations/${orgId}/breedings`),
        where("cowId", "==", cowId)
      ).withConverter(breedingConverter);
      const breedingsDocs = await getDocs(breedingsCollectionRef);
      return breedingsDocs.docs.map((doc) => doc.data());
    },
    [orgId]
  );

  const getBreedingsSub = useCallback(
    async (cowId: string, cb: (breedings: BreedingRecord[]) => void) => {
      const breedingsCollectionRef = query(
        collection(db, `organizations/${orgId}/breedings`),
        where("cowId", "==", cowId)
      ).withConverter(breedingConverter);
      return onSnapshot(breedingsCollectionRef, (snapshot) => {
        cb(
          snapshot.docs
            .map((doc) => doc.data())
            .sort((breedA, breedB) => {
              return (breedA.createdAt || "") > (breedB.createdAt || "")
                ? 1
                : -1;
            })
        );
      });
    },
    [orgId]
  );

  const addBreed = useCallback(
    (breed: BreedingRecord) => {
      addDoc(
        collection(db, `organizations/${orgId}/breedings`).withConverter(
          breedingConverter
        ),
        {
          ...breed,
          createdAt: getCurrentTimestamp(),
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const updateBreed = useCallback(
    (breedId: string, breed: Partial<Breeding>): void => {
      updateDoc(
        doc(db, `organizations/${orgId}/breedings`, breedId).withConverter(
          breedingConverter
        ),
        {
          ...breed,
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  return {
    addBreed,
    updateBreed,
    getBreedings,
    getBreedingSub,
    getBreedingsSub,
  };
}
