import { IonCard, IonCardHeader, IonCardTitle } from "@ionic/react";

interface BatchCardProps {
  batch: any;
}

const BatchCard = ({ batch }: BatchCardProps) => {
  return (
    <IonCard routerLink={`/batches/${batch.id}`}>
      <IonCardHeader>
        <IonCardTitle>{batch.shotName}</IonCardTitle>
      </IonCardHeader>
    </IonCard>
  );
};

export default BatchCard;
