import BatchCard from "./BatchCard";
import { Vaccine } from "../../../models";

interface BatchesListProps {
  batches: Vaccine[];
}

export default function BatchList({
  batches,
}: BatchesListProps): React.ReactElement {
  return (
    <>
      {batches.map((batch) => (
        <BatchCard batch={batch} key={batch.id} />
      ))}
    </>
  );
}
