import {
  IonButton,
  IonCard,
  IonIcon,
  IonLabel,
  useIonRouter,
} from "@ionic/react";
import { useState } from "react";
import { ellipsisHorizontalOutline } from "ionicons/icons";

import "./styles.css";

import MoreModal from "./MoreModal";
import { Cow } from "../../../../models";

interface CowCardProps {
  cow: Cow;
}

const CowCard = ({ cow }: CowCardProps) => {
  const router = useIonRouter();
  const [isMoreOpen, setIsMoreOpen] = useState(false);

  const onCardClicked = (e: any) => {
    if (e.target.id === "more") {
      return setIsMoreOpen(true);
    }

    router.push(`/cows/${cow.id}`, "forward");
  };

  return (
    <div>
      <IonCard className="CowCard" onClick={(e) => onCardClicked(e)}>
        <div className={"CowCardData"}>
          <h4>{cow.cowName}</h4>
          <IonLabel>{cow.location}</IonLabel>
        </div>
        <IonButton fill="clear" id="more" className="CowMore" size={"small"}>
          <IonIcon icon={ellipsisHorizontalOutline} size="small" />
        </IonButton>
      </IonCard>
      <MoreModal
        cowId={cow.id}
        isOpen={isMoreOpen}
        close={() => setIsMoreOpen(false)}
      />
    </div>
  );
};

export default CowCard;
