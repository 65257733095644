import {
  enableIndexedDbPersistence,
  initializeFirestore,
} from "firebase/firestore";
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { Capacitor } from "@capacitor/core";

// const firebaseConfig = {
//   apiKey: "AIzaSyCgYFR4Xj9yg85R04j6XlxOa5KEbDITxqs",
//   authDomain: "calving-book.firebaseapp.com",
//   projectId: "calving-book",
//   storageBucket: "calving-book.appspot.com",
//   messagingSenderId: "202804119418",
//   appId: "1:202804119418:web:f8fe60ef05be62a6ee78c3"
// };

// Sourabh's Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDK8C4kg0TOs0bB8bSUjDnNg88AhwO5V8M",
  authDomain: "calvingbook2.firebaseapp.com",
  projectId: "calvingbook2",
  storageBucket: "calvingbook2.appspot.com",
  messagingSenderId: "632293265847",
  appId: "1:632293265847:web:dff5e58d4f35d46f941832",
};

// Ellen's Firebase
// const firebaseConfig = {
//   apiKey: "AIzaSyDzEuzJIumdYnue9-xbbkrkDbSRU4HjUu8",
//   authDomain: "the-calving-book-plus.firebaseapp.com",
//   projectId: "the-calving-book-plus",
//   storageBucket: "the-calving-book-plus.appspot.com",
//   messagingSenderId: "612716363512",
//   appId: "1:612716363512:web:d79c71d2c0fa684eda450d",
//   measurementId: "G-NPK3FFB1TK",
// };
const app = initializeApp(firebaseConfig);

function whichAuth() {
  let auth1;
  if (Capacitor.isNativePlatform()) {
    auth1 = initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
    });
  } else {
    auth1 = getAuth(app);
  }
  return auth1;
}

const db = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});

enableIndexedDbPersistence(db).catch((err) => {
  console.log("err", err);
  if (err.code === "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code === "unimplemented") {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
});

export default db;
//export const auth = getAuth(app);

export const auth = whichAuth();
