import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
} from "@ionic/react";

import "./VaccineCard.css";

import { Vaccine } from "../../../models";

interface VaccineCardProps {
  vaccine: Vaccine;
  vaccineUrl: string;
}

export default function VaccineCard({
  vaccine,
  vaccineUrl,
}: VaccineCardProps): React.ReactElement {
  const url = vaccine.isBatch ? `/batches/${vaccine.batchId}` : vaccineUrl;

  return (
    <IonCard routerLink={url}>
      <IonCardHeader>
        <IonCardTitle>{`${vaccine.isBatch ? "(Batch)" : ""}${
          vaccine.shotName
        }`}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <div className={"VaccineCardContent"}>
          <div className="VaccineCardField">
            <IonLabel color={"medium"}>Dose</IonLabel>
            <IonLabel color={"primary"}>{vaccine.dose}</IonLabel>
          </div>
          <div className="VaccineCardField">
            <IonLabel color={"medium"}>Date</IonLabel>
            <IonLabel color={"primary"}>{vaccine.date}</IonLabel>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
}
