import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/common.css";

import { Login } from "./pages/Users";
import Signup from "./pages/Users/Signup";
import { useAuth } from "./Auth/AuthProvider";
import LoadingScreen from "./components/LoadingScreen";
import { AuthenticatedRoutes } from "./Auth/AuthenticatedRoutes";
import ForgotPassword from "./pages/Users/ForgotPassword";
import ResetPassword from "./pages/Users/ResetPassword";

setupIonicReact();

const App: React.FC = () => {
  const auth = useAuth();
  console.log("auth", auth);
  if (auth.isLoading) return <LoadingScreen />;
  return (
    <IonApp>
      {!auth.currentUser ? (
        <IonReactRouter>
          <IonRouterOutlet id="main">
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/signup">
              <Signup />
            </Route>
            <Route exact path="/forgot">
              <ForgotPassword />
            </Route>
            <Route exact path="/reset-password">
              <ResetPassword />
            </Route>
            <Route render={() => <Redirect to={"/login"} />} />
          </IonRouterOutlet>
        </IonReactRouter>
      ) : (
        <IonReactRouter>
          <AuthenticatedRoutes />
        </IonReactRouter>
      )}
    </IonApp>
  );
};

export default App;
