import {
  IonCard,
  IonLabel,
  IonTitle,
  IonCardHeader,
  IonCardContent,
} from "@ionic/react";
import moment from "moment";

import "./styles.css";

import { BreedingRecord } from "../../../../models/breeding";

interface BreedingRecordCardProps {
  breedingRecord: BreedingRecord;
}
export default function BreedingRecordCard({
  breedingRecord,
}: BreedingRecordCardProps): React.ReactElement {
  return (
    <IonCard
      routerLink={`/cows/${breedingRecord.cowId}/breedings/${breedingRecord.id}`}
    >
      <IonCardHeader>
        <IonTitle>{`${moment(
          breedingRecord.createdAt?.toDate()
        ).year()} Record`}</IonTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonLabel>
          Pregnant: <span>{breedingRecord.pregnant ? "Yes" : "No"}</span>
        </IonLabel>
        <IonLabel>
          Expected Date Birth: <span>{breedingRecord.expectedDateBirth}</span>
        </IonLabel>
        <IonLabel>
          Gender: <span>{breedingRecord.gender}</span>
        </IonLabel>
      </IonCardContent>
    </IonCard>
  );
}
