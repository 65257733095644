import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { IonPage, useIonRouter } from "@ionic/react";

import BookForm from "../BookForm";
import { Book } from "../../../models";
import useBooksService from "../../../services/useBooksService";

type RouteParams = { bookId: string };

export default function EditBook(): React.ReactElement {
  const router = useIonRouter();
  const { getBook, updateBook } = useBooksService();

  const [book, setBook] = useState<Book>();
  const { bookId } = useParams<RouteParams>();

  useEffect(() => {
    async function fetchBook() {
      const book = await getBook(bookId);
      setBook(book);
    }

    fetchBook();
  }, [bookId, getBook]);

  const onEditBook = (data: Book) => {
    updateBook(bookId, data);
    router.push(`/books/${bookId}`);
  };

  return (
    <IonPage>
      {book && (
        <BookForm title="Edit Book" book={book} onFormSubmit={onEditBook} />
      )}
    </IonPage>
  );
}
