import { useEffect, useState } from "react";

import { Calf } from "../../../models";
import useBooksService from "../../../services/useBooksService";

export default function useBookReport(bookId: string) {
  const { getBooksCalves } = useBooksService();
  const [calves, setCalves] = useState<Calf[]>([]);

  useEffect(() => {
    async function fetchCalves() {
      const calves = await getBooksCalves(bookId);
      setCalves(calves);
    }

    fetchCalves();
  }, [bookId, getBooksCalves]);

  const twinsSummary = (calves: Calf[]) => {
    let twins = 0;
    calves.forEach((calf) => {
      if (calf.isTwin) twins++;
    });
    return { label: "Twins", summary: twins };
  };

  const genderSummary = (calves: Calf[]) => {
    const genders = new Map<string, number>();
    genders.set("Bull", 0);
    genders.set("Steer", 0);
    genders.set("Heifer", 0);
    calves.forEach((calf) => {
      if (genders.has(calf.gender)) {
        let amount = genders.get(calf.gender) as number;
        genders.set(calf.gender, amount + 1);
      }
    });

    return Array.from(genders.keys()).map((key) => ({
      label: key,
      summary: genders.get(key) as number,
    }));
  };

  const conditionSummary = (calves: Calf[]) => {
    const conditions = new Map<string, number>();
    conditions.set("Dead", 0);
    conditions.set("Cull", 0);
    conditions.set("Paired out", 0);
    calves.forEach((calf) => {
      if (calf.isDead) {
        let deadAmount = conditions.get("Dead") as number;
        conditions.set("Dead", deadAmount + 1);
      }

      if (calf.isCull) {
        let cullAmount = conditions.get("Cull") as number;
        conditions.set("Cull", cullAmount + 1);
      }

      if (calf.isPairout) {
        let pairoutAmount = conditions.get("Paired out") as number;
        conditions.set("Paired out", pairoutAmount + 1);
      }
    });

    return Array.from(conditions.keys()).map((key) => ({
      label: key,
      summary: conditions.get(key) as number,
    }));
  };

  const generateReport = () => {
    const report: { label: string; summary: number }[] = [];

    const twinsReport = twinsSummary(calves);
    const genderReport = genderSummary(calves);
    const conditionsReport = conditionSummary(calves);
    report.push(...genderReport, twinsReport, ...conditionsReport);

    return report;
  };

  return {
    report: generateReport(),
  };
}
