import { useEffect, useState } from "react";

import { Vaccine } from "../../models";
import useVaccinesService from "../../services/useVaccinesService";

export function useVaccines(searchKey: "cowId" | "calfId", searchId: string) {
  const { getVaccinesSub } = useVaccinesService();
  const [vaccines, setVaccines] = useState<Vaccine[]>([]);

  useEffect(() => {
    getVaccinesSub(searchKey, searchId, setVaccines);
  }, [searchKey, searchId, getVaccinesSub]);

  return {
    vaccines,
  };
}
