import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { IonButton, IonContent, IonPage, useIonRouter } from "@ionic/react";

import { Cow, Vaccine } from "../../../models";
import ExcludeCowsModal from "./ExcludeCowsModal";
import useCowsService from "../../../services/useCowsService";
import VaccineForm from "../../../components/Vaccines/VaccineForm";
import useVaccinesService from "../../../services/useVaccinesService";

export default function AddCowBatchVaccine(): React.ReactElement {
  const router = useIonRouter();
  const { getCowsSub } = useCowsService();
  const [cows, setCows] = useState<Cow[]>([]);
  const [isExcludedOpen, setIsExcludedOpen] = useState(false);
  const { addBatchVaccine, addBatchToCattle } = useVaccinesService();
  const [excludedCows, setExcludedCows] = useState(new Set<string>());

  useEffect(() => {
    getCowsSub(setCows);
  }, [getCowsSub]);

  const onCowClicked = (cowId: string) => {
    if (excludedCows.has(cowId)) {
      return setExcludedCows((prev) => {
        prev.delete(cowId);
        return new Set(prev);
      });
    }

    setExcludedCows((prev) => new Set(prev.add(cowId)));
  };

  const onAddCowBatchVaccine = async (data: Vaccine) => {
    try {
      const batchId = uuidv4();

      addBatchVaccine(batchId, {
        ...data,
        isCowBatch: true,
      });
      addBatchToCattle(
        batchId,
        Array.from(excludedCows.values()),
        "cows",
        "cowId"
      );

      router.push(`/cows`);
    } catch (err) {
      alert(err);
    }
  };

  const showExcludedCowsPreview = () => {
    const excludedCowsPreview = [];
    const excludedCowsList = Array.from(excludedCows);
    const limit = excludedCowsList.length > 3 ? 3 : excludedCowsList.length;

    for (let i = 0; i < limit; i++) {
      const cowId = excludedCowsList[i];
      const cow = cows.find((cow) => cow.id === cowId) as Cow;
      excludedCowsPreview.push(`${cow.cowName}`);
    }

    excludedCowsPreview.push("...");

    return excludedCowsPreview;
  };

  return (
    <IonPage>
      <VaccineForm
        title="Add Batch Vaccine"
        backLink={`/cows`}
        onFormSubmit={onAddCowBatchVaccine}
      />
      <IonContent>
        <h6>List of Excluded Cows</h6>
        {showExcludedCowsPreview().length > 1 && (
          <p>{showExcludedCowsPreview().join(", ")}</p>
        )}
        <IonButton
          className="ion-margin"
          onClick={() => setIsExcludedOpen(true)}
        >
          Exclude Cows
        </IonButton>
        <ExcludeCowsModal
          cows={cows}
          isOpen={isExcludedOpen}
          excludedCows={excludedCows}
          onCowClicked={onCowClicked}
          close={() => setIsExcludedOpen(false)}
        />
      </IonContent>
    </IonPage>
  );
}
