import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
  useIonRouter,
} from "@ionic/react";
import { v4 as uuidv4 } from "uuid";
import { add } from "ionicons/icons";
import { useParams } from "react-router";
import { useEffect, useState } from "react";

import Header from "../../../../components/Header";
import BreedAttemptCard from "./BreedAttemptCard";
import BreedAttemptModal from "./BreedAtemptModal";
import { Breeding, BreedingRecord } from "../../../../models/breeding";
import useBreedingService from "../../../../services/useBreedingService";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import RecordForm from "../BreedingRecordForm/Form";

type RouteParams = { cowId: string; breedId: string };
export default function BreedAttemptsList(): React.ReactElement {
  const router = useIonRouter();
  const { cowId, breedId } = useParams<RouteParams>();
  const { getBreedingSub, updateBreed } = useBreedingService();
  const [breedingRecord, setBreedingRecord] = useState<BreedingRecord>();
  const [currentBreedingAttempt, setCurrentBreedingAttempt] =
    useState<number>(-1);
  const [breedingAttemptModalOpen, setBreedingAttemptModalOpen] =
    useState(false);

  const recordFormApi = useForm<BreedingRecord>({
    defaultValues: {
      gender: "",
      twins: false,
      pregnant: false,
      expectedDateBirth: undefined,
    },
  });

  const { reset, handleSubmit } = recordFormApi;

  useEffect(() => {
    getBreedingSub(breedId, (breeding) => {
      setBreedingRecord(breeding);
      reset(breeding);
    });
  }, [reset, breedId, getBreedingSub]);

  const onSaveBreedingRecord = handleSubmit((data: BreedingRecord) => {
    updateBreed(breedId, {
      ...breedingRecord,
    });
    router.push(`/cows/${cowId}`);
  });
  const onSaveBreedingAttempt = (data: Breeding) => {
    if (currentBreedingAttempt === -1) {
      breedingRecord!.breedingAttempts.push({
        ...data,
        id: uuidv4(),
      });
    } else {
      breedingRecord!.breedingAttempts[currentBreedingAttempt] = data;
    }

    updateBreed(breedId, {
      ...breedingRecord,
    });
  };

  return (
    <IonPage>
      <Header
        title={`${moment(breedingRecord?.createdAt?.toDate()).year()} Record`}
        onGoBack={() => router.goBack()}
        rightComponent={
          <IonButton
            size={"small"}
            fill={"clear"}
            onClick={onSaveBreedingRecord}
          >
            Save
          </IonButton>
        }
      />
      <IonContent>
        <FormProvider {...recordFormApi}>
          <RecordForm />
        </FormProvider>
        {breedingRecord && (
          <div>
            {breedingRecord.breedingAttempts.map((breedAttempt, idx) => (
              <BreedAttemptCard
                idx={idx + 1}
                key={breedAttempt.id}
                breedAttempt={breedAttempt}
                onCardClicked={() => {
                  setCurrentBreedingAttempt(idx);
                  setBreedingAttemptModalOpen(true);
                }}
              />
            ))}
          </div>
        )}
        {breedingRecord && (
          <IonFab slot="fixed" vertical="bottom" horizontal="end">
            <IonFabButton
              disabled={breedingRecord.breedingAttempts.length >= 3}
              onClick={() => {
                setCurrentBreedingAttempt(-1);
                setBreedingAttemptModalOpen(true);
              }}
            >
              <IonIcon icon={add}></IonIcon>
            </IonFabButton>
          </IonFab>
        )}
        {breedingRecord && (
          <BreedAttemptModal
            isOpen={breedingAttemptModalOpen}
            onFormSubmit={onSaveBreedingAttempt}
            close={() => setBreedingAttemptModalOpen(false)}
            breedAttempt={
              currentBreedingAttempt > -1
                ? breedingRecord.breedingAttempts[currentBreedingAttempt]
                : undefined
            }
          />
        )}
      </IonContent>
    </IonPage>
  );
}
