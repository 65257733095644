import { FirestoreDataConverter } from "firebase/firestore";

import { Note } from "../../models";

const noteConverter: FirestoreDataConverter<Note> = {
  toFirestore: (note) => ({
    text: note.text,
    date: note.date,
    status: note.status,
    createdAt: note.createdAt,
    updatedAt: note.updatedAt,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      text: data.text,
      date: data.date,
      status: data.status,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };
  },
};

export default noteConverter;
