import { useCallback } from "react";
import {
  doc,
  query,
  where,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  collection,
  onSnapshot,
} from "firebase/firestore";

import db from "../db/firebase";
import { Note } from "../models";
import { getCurrentTimestamp } from "../utils";
import { useAuth } from "../Auth/AuthProvider";
import { noteConverter } from "../db/converters";

export default function useNotesService() {
  const { currentUser } = useAuth();
  const orgId = currentUser?.uid!;

  const getNote = useCallback(
    async (noteId: string): Promise<Note | undefined> => {
      const noteDocRef = doc(
        db,
        `organizations/${orgId}/notes/${noteId}`
      ).withConverter(noteConverter);
      const noteDoc = await getDoc(noteDocRef);
      return noteDoc.data();
    },
    [orgId]
  );

  const getNotes = useCallback(async (): Promise<Note[]> => {
    const notesCollectingRef = query(
      collection(db, `organizations/${orgId}/notes`)
    ).withConverter(noteConverter);
    const notesDocs = await getDocs(notesCollectingRef);
    return notesDocs.docs.map((doc) => doc.data());
  }, [orgId]);

  const getNotesSub = useCallback(
    (cb: (notes: Note[]) => void, status: string = "active"): void => {
      const notesCollectionRef = query(
        collection(db, `organizations/${orgId}/notes`),
        where("status", "==", status)
      ).withConverter(noteConverter);
      onSnapshot(notesCollectionRef, (snapshot) => {
        cb(snapshot.docs.map((doc) => doc.data()));
      });
    },
    [orgId]
  );

  const addNote = useCallback(
    (note: Note): void => {
      addDoc(
        collection(db, `organizations/${orgId}/notes`).withConverter(
          noteConverter
        ),
        {
          ...note,
          status: "active",
          createdAt: getCurrentTimestamp(),
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const updateNote = useCallback(
    (noteId: string, note: Note): void => {
      updateDoc(
        doc(db, `organizations/${orgId}/notes`, noteId).withConverter(
          noteConverter
        ),
        { ...note, updatedAt: getCurrentTimestamp() }
      );
    },
    [orgId]
  );

  const deleteNote = useCallback(
    (noteId: string): void => {
      updateDoc(
        doc(db, `organizations/${orgId}/notes`, noteId).withConverter(
          noteConverter
        ),
        {
          status: "deleted",
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  return {
    addNote,
    getNote,
    getNotes,
    updateNote,
    getNotesSub,
    deleteNote,
  };
}
