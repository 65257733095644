import { IonIcon } from "@ionic/react";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { useSwiper } from "swiper/react";

interface TwinNavigationProps {
  label: string;
}
export default function TwinNavigation({ label }: TwinNavigationProps) {
  const swiper = useSwiper();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <IonIcon
        slot={"start"}
        color={"primary"}
        icon={chevronBackOutline}
        onClick={() => swiper.slidePrev()}
      />
      <h3>{label}</h3>
      <IonIcon
        slot={"end"}
        color={"primary"}
        icon={chevronForwardOutline}
        onClick={() => swiper.slideNext()}
      />
    </div>
  );
}
