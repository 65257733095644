import { createContext, useContext, useState } from "react";

import { Calf } from "../../../../models";

type SortBy = keyof Calf;
type SortDirection = "asc" | "desc";

export const BookSearchContext = createContext<{
  sortBy: SortBy;
  searchValue: string;
  sortDirection: SortDirection;
  onSortDirectionChange: () => void;
  onSortByChange: (sortBy: SortBy) => void;
  onSearchValueChange: (searchValue: string) => void;
} | null>(null);

export const BookSearchProvider = ({ children }: { children: any }) => {
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState<SortBy>("createdAt");
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc");

  const onSortByChange = (sortBy: SortBy) => {
    setSortBy(sortBy);
  };

  const onSearchValueChange = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const onSortDirectionChange = () => {
    if (sortDirection === "asc") return setSortDirection("desc");
    setSortDirection("asc");
  };

  const values = {
    sortBy,
    searchValue,
    sortDirection,
    onSortByChange,
    onSearchValueChange,
    onSortDirectionChange,
  };

  return (
    <BookSearchContext.Provider value={values}>
      {children}
    </BookSearchContext.Provider>
  );
};

export const useBookSearch = () => {
  const bookSearch = useContext(BookSearchContext);

  if (!bookSearch) {
    throw new Error(
      "useBookSearch has to be used within <BookSearchContext.Provider>"
    );
  }

  return bookSearch;
};
