import {
  doc,
  query,
  where,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  onSnapshot,
  collection,
  setDoc,
} from "firebase/firestore";
import { useCallback } from "react";

import db from "../db/firebase";
import { Calf, Sire } from "../models";
import { getCurrentTimestamp } from "../utils";
import { useAuth } from "../Auth/AuthProvider";
import { calfConverter, sireConverter } from "../db/converters";

export default function useSiresService() {
  const { currentUser } = useAuth();
  const orgId = currentUser?.uid!;

  const getSire = useCallback(
    async (sireId: string): Promise<Sire | undefined> => {
      const sireDocRef = doc(
        db,
        `organizations/${orgId}/sires/${sireId}`
      ).withConverter(sireConverter);
      const sireDoc = await getDoc(sireDocRef);
      return sireDoc.data();
    },
    [orgId]
  );

  const getSires = useCallback(async (): Promise<Sire[]> => {
    const siresCollectionRef = query(
      collection(db, `organizations/${orgId}/sires`)
    ).withConverter(sireConverter);
    const siresDocs = await getDocs(siresCollectionRef);
    return siresDocs.docs.map((doc) => doc.data());
  }, [orgId]);

  const getSiresSub = useCallback(
    (cb: (sires: Sire[]) => void): void => {
      const siresCollectionRef = query(
        collection(db, `organizations/${orgId}/sires`)
      ).withConverter(sireConverter);
      onSnapshot(siresCollectionRef, (snapshot) => {
        cb(snapshot.docs.map((doc) => doc.data()));
      });
    },
    [orgId]
  );

  const addSire = useCallback(
    (sire: Sire): void => {
      addDoc(
        collection(db, `organizations/${orgId}/sires`).withConverter(
          sireConverter
        ),
        {
          ...sire,
          createdAt: getCurrentTimestamp(),
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const addQuickSire = useCallback(
    (sireId: string, sire: Partial<Sire>) => {
      setDoc<Partial<Sire>>(
        doc(db, `organizations/${orgId}/sires`, sireId).withConverter(
          sireConverter
        ),
        {
          ...sire,
          createdAt: getCurrentTimestamp(),
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const updateSire = useCallback(
    (sireId: string, sire: Sire): void => {
      updateDoc(
        doc(db, `organizations/${orgId}/sires`, sireId).withConverter(
          sireConverter
        ),
        {
          ...sire,
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const deleteSire = useCallback(
    async (sireId: string) => {
      updateDoc(
        doc(db, `organizations/${orgId}/sires`, sireId).withConverter(
          sireConverter
        ),
        {
          status: "deleted",
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const getSiresCalvesSub = useCallback(
    (
      sireId: string,
      cb: (calves: Calf[]) => void,
      status: string = "active"
    ): void => {
      const calvesCollectionRef = query(
        collection(db, `organizations/${orgId}/calves`),
        where("sireId", "==", sireId),
        where("status", "==", status)
      ).withConverter(calfConverter);
      onSnapshot(calvesCollectionRef, (snapshot) => {
        cb(snapshot.docs.map((doc) => doc.data()));
      });
    },
    [orgId]
  );

  return {
    addSire,
    getSire,
    getSires,
    updateSire,
    deleteSire,
    getSiresSub,
    addQuickSire,
    getSiresCalvesSub,
  };
}
