import {
  doc,
  query,
  where,
  limit,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  collection,
  onSnapshot,
} from "firebase/firestore";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

import db from "../db/firebase";
import { Calf, Cow } from "../models";
import { getCurrentTimestamp } from "../utils";
import { useAuth } from "../Auth/AuthProvider";
import useCalvesService from "./useCalvesService";
import { calfConverter, cowConverter } from "../db/converters";

export default function useCowsService() {
  const { currentUser } = useAuth();
  const orgId = currentUser?.uid!;

  const { updateCalvesCowName } = useCalvesService();

  const getCow = useCallback(
    async (cowId: string): Promise<Cow | undefined> => {
      const cowDocRef = doc(
        db,
        `organizations/${orgId}/cows`,
        cowId
      ).withConverter(cowConverter);
      const cowDoc = await getDoc(cowDocRef);
      return cowDoc.data();
    },
    [orgId]
  );

  const getCows = useCallback(async (): Promise<Cow[]> => {
    const cowsCollectionRef = query(
      collection(db, `organizations/${orgId}/cows`)
    ).withConverter(cowConverter);
    const cowsDocs = await getDocs(cowsCollectionRef);
    return cowsDocs.docs.map((doc) => doc.data());
  }, [orgId]);

  const getCowsSub = useCallback(
    (cb: (cows: Cow[]) => void, status: string = "active"): void => {
      const cowsCollectionRef = query(
        collection(db, `organizations/${orgId}/cows`),
        where("status", "==", status)
      ).withConverter(cowConverter);
      onSnapshot(cowsCollectionRef, (snapshot) => {
        cb(snapshot.docs.map((doc) => doc.data()));
      });
    },
    [orgId]
  );

  const getCowByName = useCallback(
    async (cowName: string): Promise<Cow | undefined> => {
      const cowsCollectionRef = query(
        collection(db, `organizations/${orgId}/cows`),
        where("cowName", "==", cowName),
        limit(1)
      ).withConverter(cowConverter);
      const cowDoc = await getDocs(cowsCollectionRef);
      if (cowDoc.size > 0) {
        return cowDoc.docs[0].data();
      }
    },
    [orgId]
  );

  const addCow = useCallback(
    async (cowId: string, cow: Partial<Cow>): Promise<void> => {
      const doesExists = await getCowByName(cow.cowName!);

      if (doesExists) throw new Error("Cow name already exists.");

      const newCow = {
        ...cow,
        status: "active",
      };
      setDoc<Partial<Cow>>(
        doc(db, `organizations/${orgId}/cows`, cowId).withConverter(
          cowConverter
        ),
        {
          ...newCow,
          createdAt: getCurrentTimestamp(),
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId, getCowByName]
  );

  const updateCow = useCallback(
    async (cowId: string, cow: Cow): Promise<void> => {
      const originalCow = (await getCow(cowId))!;
      const doesExists = await getCowByName(cow.cowName!);

      if (doesExists && doesExists.id !== cowId)
        throw new Error("Cow name already exists.");

      if (originalCow.cowName !== cow.cowName) {
        updateCalvesCowName(cow.cowName, originalCow.cowName);
      }

      updateDoc(
        doc(db, `organizations/${orgId}/cows`, cowId).withConverter(
          cowConverter
        ),
        {
          ...cow,
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId, getCow, getCowByName, updateCalvesCowName]
  );

  const deleteCow = useCallback(
    async (cowId: string) => {
      updateDoc(
        doc(db, `organizations/${orgId}/cows`, cowId).withConverter(
          cowConverter
        ),
        {
          status: "deleted",
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const upsertCow = useCallback(
    async (cowName: string): Promise<void> => {
      const doesExists = await getCowByName(cowName);
      if (!doesExists) {
        const cowId = uuidv4();
        addCow(cowId, {
          cowName,
        });
      }
    },
    [addCow, getCowByName]
  );

  const getCowCalvesSub = useCallback(
    (
      cowId: string,
      cb: (calves: Calf[]) => void,
      status: string = "active"
    ): void => {
      const calvesCollectionRef = query(
        collection(db, `organizations/${orgId}/calves`),
        where("cowId", "==", cowId),
        where("status", "==", status)
      ).withConverter(calfConverter);
      onSnapshot(calvesCollectionRef, (snapshot) => {
        cb(snapshot.docs.map((doc) => doc.data()));
      });
    },
    [orgId]
  );

  return {
    addCow,
    getCow,
    getCows,
    updateCow,
    upsertCow,
    deleteCow,
    getCowsSub,
    getCowCalvesSub,
  };
}
