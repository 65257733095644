import { Book, Calf } from "../models";
import useBooksService from "../services/useBooksService";
import { CalfReport } from "../models/calf";
import useSiresService from "../services/useSiresService";
import { useEffect, useState } from "react";

export default function useBookCalvesReport(bookId: string) {
  const [book, setBook] = useState<Book>();
  const { getSires } = useSiresService();
  const { getBook, getBooksCalves } = useBooksService();

  useEffect(() => {
    async function fetchBook() {
      const book = await getBook(bookId);
      setBook(book);
    }
    fetchBook();
  }, [bookId, getBook]);
  async function getReport(): Promise<CalfReport[]> {
    const sires = await getSires();
    const book = await getBook(bookId);
    const calves = await getBooksCalves(bookId);

    const reportData: CalfReport[] = [];

    calves
      .sort((calfA, calfB) => {
        if (calfA.createdAt! < calfB.createdAt!) {
          return -1;
        }
        if (calfA.createdAt! > calfB.createdAt!) {
          return 1;
        }
        return 0;
      })
      .forEach((calf) => {
        const sire = sires.find((sire) => sire.id === calf.sireId);
        const sireName = sire ? sire.sireName : "-";
        const bookName = book ? book?.name : "-";
        reportData.push(createReportDataRow(calf, bookName, sireName));
      });
    console.log(reportData);
    return reportData;
  }

  function createReportDataRow(
    calf: Calf,
    bookName: string,
    sireName: string
  ): CalfReport {
    return {
      cowName: calf.cowName!,
      calfName: calf.calfName!,
      weight: calf.weight || "-",
      gender: calf.gender || "-",
      dateOfBirth: calf.dateOfBirth || "-",
      sireName,
      color: calf.color || "-",
      calvinEase: calf.calvinEase || "-",
      bookName,
      isDead: calf.isDead ? "Y" : "-",
      comment: calf.comment || "-",
      isPairout: calf.isPairout ? "Y" : "-",
      isCull: calf.isCull ? "Y" : "-",
      udderGrade: calf.udderGrade || "-",
      tagColor: calf.tagColor || "-",
      weaningDate: calf.weaningDate || "-",
      weaningWeight: calf.weaningWeight || "-",
    };
  }

  return {
    book,
    getReport,
  };
}
