import {
  IonFab,
  IonIcon,
  IonPage,
  IonContent,
  IonFabButton,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { useEffect, useState } from "react";

import NoteCard from "./NoteCard";
import { Note } from "../../../models";
import useNotesService from "../../../services/useNotesService";
import Header from "../../../components/Header";

export default function NotesList(): React.ReactElement {
  const { getNotesSub } = useNotesService();
  const [notes, setNotes] = useState<Note[]>([]);

  useEffect(() => {
    getNotesSub(setNotes);
  }, [getNotesSub]);

  return (
    <IonPage>
      <Header title={"Notes"} />
      <IonContent>
        {notes.map((note) => (
          <NoteCard key={note.id} note={note} />
        ))}
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton routerLink="/add-note">
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
}
