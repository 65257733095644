import {
  IonCard,
  IonPage,
  IonInput,
  IonButton,
  IonContent,
  useIonRouter,
} from "@ionic/react";
import { useForm } from "react-hook-form";

import { useAuth } from "../../Auth/AuthProvider";
import { OrgData } from "../../models/organization";
import FormControl from "../../components/FormControl/FormControl";
import Header from "../../components/Header";

interface SignupFormData {
  companyName: string;
  companyAddress: string;
  companyZipCode: string;
  companyCity: string;
  companyState: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
}

const Signup: React.FC = () => {
  const auth = useAuth();
  const router = useIonRouter();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<SignupFormData>({
    defaultValues: {
      companyName: "",
      companyAddress: "",
      companyZipCode: "",
      companyCity: "",
      companyState: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneNumber: "",
    },
  });

  const handleSignup = async (data: SignupFormData) => {
    try {
      const orgData: OrgData = {
        cowName: false,
        calfName: true,
        email: data.email,
        companyName: data.companyName,
        companyAddress: data.companyAddress,
        companyCity: data.companyCity,
        companyState: data.companyState,
        companyZipCode: data.companyZipCode,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
      };

      await auth.signup(data.email, data.password, orgData);

      router.push("/books");
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <IonPage>
      <form onSubmit={handleSubmit(handleSignup)}>
        <Header title={"Signup"} onGoBack={() => router.push("/login")} />
        <IonContent fullscreen className={"ion-padding"}>
          <IonCard className={"ion-no-margin"}>
            <FormControl
              label={"First Name"}
              required
              error={!!errors.firstName}
            >
              <IonInput
                {...register("firstName", {
                  required: "This is a required field",
                })}
                onIonChange={(e) =>
                  setValue("firstName", e.detail.value!, {
                    shouldValidate: true,
                  })
                }
              />
            </FormControl>
            <FormControl label={"Last Name"} required error={!!errors.lastName}>
              <IonInput
                {...register("lastName", {
                  required: "This is a required field",
                })}
                onIonChange={(e) =>
                  setValue("lastName", e.detail.value!, {
                    shouldValidate: true,
                  })
                }
              />
            </FormControl>
            <FormControl label={"Buisness Name"}>
              <IonInput
                {...register("companyName", {})}
                onIonChange={(e) =>
                  setValue("companyName", e.detail.value!, {
                    shouldValidate: true,
                  })
                }
              />
            </FormControl>
            <FormControl label={"Email"} required error={!!errors.email}>
              <IonInput
                {...register("email", {
                  required: "This is a required field",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "invalid email address",
                  },
                })}
                onIonChange={(e) =>
                  setValue("email", e.detail.value!, {
                    shouldValidate: true,
                  })
                }
              />
            </FormControl>
            <FormControl
              label={"Password (min 8)"}
              required
              error={!!errors.password}
            >
              <IonInput
                {...register("password", {
                  required: "This is a required field",
                  minLength: 8,
                  maxLength: 20,
                })}
                onIonChange={(e) =>
                  setValue("password", e.detail.value!, {
                    shouldValidate: true,
                  })
                }
                type="password"
              />
            </FormControl>
            <FormControl
              label={"Phone Number"}
              required
              error={!!errors.phoneNumber}
            >
              <IonInput
                {...register("phoneNumber", {
                  required: "This is a required field",
                })}
                onIonChange={(e) => {
                  setValue("phoneNumber", e.detail.value!, {
                    shouldValidate: true,
                  });
                }}
              />
            </FormControl>
            <FormControl label={"Address"}>
              <IonInput
                {...register("companyAddress", {})}
                onIonChange={(e) => {
                  setValue("companyAddress", e.detail.value!, {
                    shouldValidate: true,
                  });
                }}
              />
            </FormControl>

            <FormControl label={"City"}>
              <IonInput {...register("companyCity", {})} />
            </FormControl>
            <FormControl label={"State"}>
              <IonInput {...register("companyState", {})} />
            </FormControl>
            <FormControl label={"Zip Code"}>
              <IonInput {...register("companyZipCode", {})} />
            </FormControl>
          </IonCard>
          {/*<IonCard>*/}
          <IonButton expand="full" type="submit" disabled={!isValid}>
            Sign Up
          </IonButton>
          {/*</IonCard>*/}
        </IonContent>
      </form>
    </IonPage>
  );
};

export default Signup;
