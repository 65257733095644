import { IonPage } from "@ionic/react";
import { useParams } from "react-router";
import { useEffect, useState } from "react";

import { Calf } from "../../../models";
import BornCalvesSummary from "../../../components/CalvesSummary";
import useSiresService from "../../../services/useSiresService";

type RouteParams = { sireId: string };

export default function SireSummary(): React.ReactElement {
  const { sireId } = useParams<RouteParams>();
  const { getSiresCalvesSub } = useSiresService();
  const [calves, setCalves] = useState<Calf[]>([]);

  useEffect(() => {
    getSiresCalvesSub(sireId, setCalves);
  }, [sireId, getSiresCalvesSub]);

  return (
    <IonPage>
      <BornCalvesSummary calves={calves} navigateBackTo={`/sires`} />
    </IonPage>
  );
}
