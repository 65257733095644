import { FirestoreDataConverter } from "firebase/firestore";

import { BreedingRecord } from "../../models/breeding";

const breedingConverter: FirestoreDataConverter<BreedingRecord> = {
  toFirestore: (breed) => ({
    cowId: breed.cowId,
    twins: breed.twins,
    gender: breed.gender,
    pregnant: breed.pregnant,
    expectedDateBirth: breed.expectedDateBirth,
    breedingAttempts: breed.breedingAttempts,
    createdAt: breed.createdAt,
    updatedAt: breed.updatedAt,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      cowId: data.cowId,
      twins: data.twins,
      gender: data.gender,
      pregnant: data.pregnant,
      expectedDateBirth: data.expectedDateBirth,
      breedingAttempts: data.breedingAttempts,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };
  },
};

export default breedingConverter;
