import { saveAs } from "file-saver";
import { Share } from "@capacitor/share";
import { isPlatform } from "@ionic/react";
import { usePapaParse } from "react-papaparse";
import * as pdfMake from "pdfmake/build/pdfmake";
import { TCreatedPdf } from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
export default function useExport() {
  const { jsonToCSV } = usePapaParse();
  async function exportData(filename: string, csv: string, pdf: TCreatedPdf) {
    if (isPlatform("mobile")) {
      const pdfBase64: string = await new Promise((res) => {
        pdf.getBase64((data) => {
          res(data);
        });
      });

      const csvFile = await Filesystem.writeFile({
        data: csv,
        recursive: true,
        encoding: Encoding.UTF8,
        directory: Directory.Documents,
        path: `exports/${filename}.csv`,
      });

      const pdfFile = await Filesystem.writeFile({
        data: pdfBase64,
        recursive: true,
        directory: Directory.Documents,
        path: `exports/${filename}.pdf`,
      });

      await Share.share({
        title: "The Calving Book - Exported Data",
        text: "Attached are your exported records",
        files: [csvFile.uri, pdfFile.uri],
      });
    } else {
      const blob = new Blob([csv], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, `${filename}.csv`);

      pdf.download(`${filename}.pdf`);
    }
  }
  function exportDataCsv<T>(
    data: T[],
    headers: { label: string; key: keyof T }[]
  ) {
    const json = data.map((item) => {
      const row: any = {};
      headers.forEach((header) => {
        row[header.label] = item[header.key];
      });
      return row;
    });
    return jsonToCSV(json);
  }

  function exportDataPdf<T>(
    title: string,
    headers: { label: string; key: keyof T; width: string | number }[],
    data: T[]
  ) {
    const pdfBody = generateTableBody<T>(title, headers, data);
    return pdfMake.createPdf(pdfBody);
  }

  function generateTableBody<T>(
    title: string,
    headers: { label: string; key: keyof T; width: string | number }[],
    data: any[]
  ): TDocumentDefinitions {
    const body: string[][] = [headers.map((header) => header.label)];
    data.forEach((data) => {
      const row: string[] = [];
      headers.forEach((header) => {
        row.push(data[header.key] || "");
      });
      body.push(row);
    });
    return {
      content: [
        {
          text: `The Calving Book: ${title}`,
          style: {
            bold: true,
            alignment: "center",
            fontSize: 18,
          },
        },
        {
          table: {
            body,
            widths: headers.map((header) => header.width),
          },
          style: {
            fontSize: 10,
          },
        },
      ],
      pageSize: "A4",
      pageOrientation: "landscape",
    };
  }

  return {
    exportData,
    exportDataCsv,
    exportDataPdf,
  };
}
