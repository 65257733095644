import { useCallback } from "react";
import {
  doc,
  query,
  limit,
  where,
  getDoc,
  setDoc,
  getDocs,
  updateDoc,
  collection,
  onSnapshot,
} from "firebase/firestore";

import db from "../db/firebase";
import { Calf } from "../models";
import { useAuth } from "../Auth/AuthProvider";
import { getCurrentTimestamp } from "../utils";
import useBooksService from "./useBooksService";
import { calfConverter } from "../db/converters";

export default function useCalvesService() {
  const { currentUser } = useAuth();
  const orgId = currentUser?.uid!;
  const { updateCalvesCount } = useBooksService();

  const getCalf = useCallback(
    async (calfId: string): Promise<Calf | undefined> => {
      const calfDocRef = doc(
        db,
        `organizations/${orgId}/calves`,
        calfId
      ).withConverter(calfConverter);
      const calfDoc = await getDoc(calfDocRef);
      return calfDoc.data();
    },
    [orgId]
  );

  const getCalfSub = useCallback(
    (calfId: string, cb: (calf: Calf | undefined) => void): void => {
      const calfDocRef = doc(
        db,
        `organizations/${orgId}/calves`,
        calfId
      ).withConverter(calfConverter);
      onSnapshot(calfDocRef, (snapshot) => {
        cb(snapshot.data());
      });
    },
    [orgId]
  );

  const getCalfByNameAndBook = useCallback(
    async (calfName: string, bookId: string): Promise<Calf | undefined> => {
      const calvesCollectionRef = query(
        collection(db, `organizations/${orgId}/calves`),
        where("calfName", "==", calfName),
        where("bookId", "==", bookId),
        limit(1)
      ).withConverter(calfConverter);
      const calfDoc = await getDocs(calvesCollectionRef);
      if (calfDoc.size > 0) {
        return calfDoc.docs[0].data();
      }
    },
    [orgId]
  );

  const addCalf = useCallback(
    (calfId: string, calf: Calf): void => {
      setDoc(
        doc(db, `organizations/${orgId}/calves`, calfId).withConverter(
          calfConverter
        ),
        {
          ...calf,
          status: "active",
          createdAt: getCurrentTimestamp(),
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const updateCalf = useCallback(
    async (calfId: string, calf: Partial<Calf>): Promise<void> => {
      const currentCalf = (await getCalf(calfId))!;

      if (currentCalf.bookId !== calf.bookId) {
        updateCalvesCount(calf.bookId!, 1);
        updateCalvesCount(currentCalf.bookId, -1);
      }

      updateDoc(
        doc(db, `organizations/${orgId}/calves`, calfId).withConverter(
          calfConverter
        ),
        {
          ...calf,
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId, getCalf, updateCalvesCount]
  );

  const deleteCalf = useCallback(
    async (calfId: string) => {
      const calf = (await getCalf(calfId))!;
      updateCalvesCount(calf.bookId, -1);

      updateDoc(
        doc(db, `organizations/${orgId}/calves`, calfId).withConverter(
          calfConverter
        ),
        {
          status: "deleted",
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId, getCalf, updateCalvesCount]
  );

  const updateCalvesCowName = useCallback(
    async (newCowName: string, originalCowName: string): Promise<void> => {
      const calvesCollectionRef = query(
        collection(db, `organizations/${orgId}/calves`),
        where("cowName", "==", originalCowName)
      ).withConverter(calfConverter);
      const calvesDocs = await getDocs(calvesCollectionRef);
      calvesDocs.forEach((calfDoc) => {
        updateDoc(
          doc(db, `organizations/${orgId}/calves`, calfDoc.id).withConverter(
            calfConverter
          ),
          {
            ...calfDoc.data(),
            cowName: newCowName,
            updatedAt: getCurrentTimestamp(),
          }
        );
      });
    },
    [orgId]
  );

  return {
    addCalf,
    getCalf,
    updateCalf,
    deleteCalf,
    getCalfSub,
    updateCalvesCowName,
    getCalfByNameAndBook,
  };
}
