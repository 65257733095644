import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { IonPage, useIonRouter } from "@ionic/react";

import { Vaccine } from "../../../models";
import VaccineForm from "../../../components/Vaccines/VaccineForm";
import useVaccinesService from "../../../services/useVaccinesService";

type RouteParams = { cowId: string; vaccineId: string };

export default function EditCowVaccine(): React.ReactElement {
  const router = useIonRouter();
  const [vaccine, setVaccine] = useState<Vaccine>();
  const { cowId, vaccineId } = useParams<RouteParams>();
  const { getVaccine, updateVaccine } = useVaccinesService();

  useEffect(() => {
    async function fetchVaccine() {
      const vaccine = await getVaccine(vaccineId);
      setVaccine(vaccine);
    }

    fetchVaccine();
  }, [cowId, vaccineId, getVaccine]);

  const onEditCowVaccine = async (data: Vaccine) => {
    updateVaccine(vaccineId, data);
    router.push(`/cows/${cowId}`);
  };

  return (
    <IonPage>
      {vaccine && (
        <VaccineForm
          vaccine={vaccine}
          title="Edit Vaccine"
          backLink={`/cows/${cowId}`}
          onFormSubmit={onEditCowVaccine}
        />
      )}
    </IonPage>
  );
}
