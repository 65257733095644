import { useEffect, useState } from "react";
import { IonPage } from "@ionic/react";
import { useParams } from "react-router";

import { Calf } from "../../../models";
import useBooksService from "../../../services/useBooksService";
import BornCalvesSummary from "../../../components/CalvesSummary";

type RouteParams = { bookId: string };

export default function BookSummary(): React.ReactElement {
  const { bookId } = useParams<RouteParams>();
  const { getBookCalvesSub } = useBooksService();
  const [calves, setCalves] = useState<Calf[]>([]);

  useEffect(() => {
    getBookCalvesSub(bookId, setCalves);
  }, [bookId, getBookCalvesSub]);

  return (
    <IonPage>
      <BornCalvesSummary calves={calves} navigateBackTo={`/books`} />
    </IonPage>
  );
}
