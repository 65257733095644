import {
  IonCard,
  IonIcon,
  IonTitle,
  IonInput,
  IonHeader,
  IonButton,
  IonToolbar,
  IonButtons,
  IonContent,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { chevronBackOutline } from "ionicons/icons";

import "./styles.css";

import { Book } from "../../../models";
import { useGoBack } from "../../../hooks/useGoBack";
import FormControl from "../../../components/FormControl/FormControl";

interface BookFormProps {
  title: string;
  book?: Partial<Book>;
  onFormSubmit: (data: Book) => void;
}

export default function BookForm({
  book,
  title,
  onFormSubmit,
}: BookFormProps): React.ReactElement {
  const {
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { isValid, isDirty, errors },
  } = useForm<Book>({
    defaultValues: {
      name: book?.name || "",
    },
  });

  const goBack = useGoBack({
    guardValue: isDirty,
    navigateTo: "/books",
    routerDirection: "back",
    onOkGuardHandler: () => {
      reset();
    },
  });

  const onSubmit = handleSubmit((data) => {
    onFormSubmit(data);
    reset();
  });

  return (
    <form onSubmit={onSubmit} className="BookForm">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton fill="clear" onClick={goBack}>
              <IonIcon icon={chevronBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton fill="clear" type="submit" disabled={!isValid}>
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <FormControl label={"Book Name"} required error={!!errors.name}>
            <IonInput
              {...register("name", {
                required: "This is a required field",
                minLength: 1,
              })}
              onIonChange={(e) =>
                setValue("name", e.target.value as string, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              placeholder="Book Name"
            />
          </FormControl>
        </IonCard>
      </IonContent>
    </form>
  );
}
