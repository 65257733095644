import {
  IonPage,
  IonButton,
  IonContent,
  useIonToast,
  IonImg,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";

import "./styles.css";

import logo from "../../../assets/logo.png";

import { useAuth } from "../../../Auth/AuthProvider";

import Logo from "./Logo";
const Login: React.FC = () => {
  const auth = useAuth();
  const [present] = useIonToast();
  const currentUser = auth.currentUser;
  const [isAuthed, setIsAuthed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  //const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  let history = useHistory();

  const {
    register,
    handleSubmit,
    //formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    setIsAuthed(!!currentUser);
    setIsLoading(false);
  }, [currentUser]);

  const submitLogin = async (data: any) => {
    //setIsSubmitting(true);
    try {
      await auth.login(data.email, data.password);
      history.push(`/books`);
      //setIsSubmitting(false);
    } catch (err: any) {
      if (err.code === "auth/user-not-found") {
        present({
          message: "User does not exist.",
          duration: 1500,
          position: "top",
          color: "danger",
        });
      }

      if (err.code === "auth/wrong-password") {
        present({
          message: "Password is wrong.",
          duration: 1500,
          position: "top",
          color: "danger",
        });
      }
      //setIsSubmitting(false);
    }
  };

  return isLoading ? null : isAuthed ? (
    <Redirect to="/books" />
  ) : (
    <IonPage>
      <IonContent fullscreen scrollY={false}>
        <div className={"Login"}>
          <div className={"LoginMain"}>
            <Logo />
            <IonImg style={{ width: 110, height: 110 }} src={logo} />
            <span className={"SignupText"}>
              Don’t have an account?
              <IonButton
                className={"ion-no-padding"}
                routerLink="/signup"
                fill="clear"
                expand="full"
                size="small"
              >
                Signup
              </IonButton>
            </span>
            {/* <h3>Login</h3> */}
            <form onSubmit={handleSubmit(submitLogin)}>
              <TextField
                {...register("email", {
                  required: "This is a required field",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "invalid email address",
                  },
                })}
                fullWidth
                size={"small"}
                placeholder={"Email"}
              />

              <TextField
                {...register("password", {
                  required: "This is a required field",
                })}
                type="password"
                fullWidth
                size={"small"}
                placeholder={"Password"}
              />

              <IonButton expand="full" shape={"round"} type="submit">
                Login
              </IonButton>
            </form>
            <IonButton
              fill="clear"
              size="small"
              expand="full"
              routerLink="/forgot"
            >
              Forgot Password
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
