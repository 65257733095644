import { IonPage, useIonRouter } from "@ionic/react";

import SireForm from "../SireForm";
import { Sire } from "../../../models";
import useSiresService from "../../../services/useSiresService";

export default function AddSire(): React.ReactElement {
  const router = useIonRouter();
  const { addSire } = useSiresService();

  const onAddSire = async (data: Sire) => {
    try {
      addSire(data);
      router.push("/sires");
    } catch (err) {
      alert(err);
    }
  };
  return (
    <IonPage>
      <SireForm title="Add Sire" onFormSubmit={onAddSire} />
    </IonPage>
  );
}
