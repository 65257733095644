import { useState, useEffect } from "react";
import { query, collection, getDocs, setDoc, doc } from "firebase/firestore";

import db from "../db/firebase";
import { useAuth } from "../Auth/AuthProvider";

export function useColors() {
  const auth = useAuth();
  const orgId = auth.currentUser?.uid!;
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    async function fetchColors() {
      const colorsCollectionRef = query(
        collection(db, `organizations/${orgId}/refColors`)
      );
      const colorsDocs = await getDocs(colorsCollectionRef);
      setColors(colorsDocs.docs.map((doc) => doc.id));
    }

    fetchColors();
  }, [orgId]);

  const insertNewColor = async (color: string) => {
    if (!!color && !colors.includes(color)) {
      setDoc(doc(db, `organizations/${orgId}/refColors`, color), {});
      setColors([...colors, color]);
    }
  };

  return {
    colors,
    insertNewColor,
  };
}
