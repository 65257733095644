import "./styles.css";
interface FormControlProps {
  label: string;
  error?: boolean;
  required?: boolean;
  labelWidth?: string;
  children: React.ReactNode;
}
export default function FormControl({
  label,
  error,
  children,
  labelWidth,
  required,
}: FormControlProps) {
  return (
    <div className={"FormControl"}>
      <label
        style={{
          width: labelWidth ? labelWidth : undefined,
          display: "flex",
          alignItems: "center",
        }}
      >
        {label}
        {required && <span style={{ color: error ? "#eb445a" : "" }}>*</span>}
      </label>
      <div style={{ flex: 1 }}>{children}</div>
    </div>
  );
}
