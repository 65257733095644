import { IonPage, useIonRouter } from "@ionic/react";

import NoteForm from "../NoteForm";
import { Note } from "../../../models";

import useNotesService from "../../../services/useNotesService";

export default function AddNote(): React.ReactElement {
  const router = useIonRouter();
  const { addNote } = useNotesService();

  const onAddNote = async (data: Note) => {
    try {
      addNote(data);
      router.push("/notes");
    } catch (err) {
      alert(err);
    }
  };
  return (
    <IonPage>
      <NoteForm title="Add Note" onFormSubmit={onAddNote} />
    </IonPage>
  );
}
