import {
  User,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import moment from "moment";
import { Glassfy, GlassfyOffering, GlassfySku } from "capacitor-plugin-glassfy";
import { createContext, useContext, useEffect, useState } from "react";

import db, { auth } from "../db/firebase";
import { isPlatform } from "@ionic/react";
import { OrgData } from "../models/organization";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { createOrganization } from "../services/useOrganizationsService";
import organizationConverter from "../db/converters/organizationConverter";

type ACCOUNT_STATUS = "trial" | "subscribed" | "unsubscribed";
export const AuthContext = createContext<{
  login: Function;
  logout: Function;
  isLoading: boolean;
  trialPeriod: boolean;
  currentUser: User | null;
  accountStatus: ACCOUNT_STATUS;
  currentOrg: OrgData | undefined;
  appOfferings: GlassfyOffering[];
  subscribe: (sku: GlassfySku) => Promise<void>;
  recoverPassword: (email: string) => Promise<void>;
  signup: (email: string, password: string, orgData: OrgData) => Promise<void>;
} | null>(null);

export const AuthProvider = ({ children }: { children: any }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [trialPeriod, setTrialPeriod] = useState(false);
  const [currentOrg, setCurrentOrg] = useState<OrgData | undefined>();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [accountStatus, setAccountStatus] =
    useState<ACCOUNT_STATUS>("unsubscribed");
  const [appOfferings, setAppOfferings] = useState<GlassfyOffering[]>([]);

  useEffect(() => {
    const initGlassfy = async () => {
      try {
        await Glassfy.initialize({
          apiKey: "906d11023eb74ac5b9639add095922a1",
          watcherMode: false,
        });
      } catch (err) {
        console.log("Glassfy error", err);
      }
    };

    onAuthStateChanged(auth, async (user) => {
      setIsLoading(true);
      let status: ACCOUNT_STATUS = "unsubscribed";
      let organization = undefined;

      if (isPlatform("mobile") && !isPlatform("mobileweb")) {
        initGlassfy();
      }

      if (user) {
        const organizationDocRef = doc(
          db,
          `organizations/${user.uid}`
        ).withConverter(organizationConverter);
        const organizationDoc = await getDoc(organizationDocRef);
        organization = organizationDoc.data();
      }

      if (organization && isPlatform("mobile") && !isPlatform("mobileweb")) {
        await Glassfy.connectCustomSubscriber({
          //subscriberId: user.uid,
          subscriberId: organization.id!,
        });
        const permissions = await Glassfy.permissions();
        const offerings = await Glassfy.offerings();
        setAppOfferings(offerings.all);
        const { isValid, expireDate } = permissions.all[0];
        const formattedExpireDate = moment
          .unix(parseInt(expireDate))
          .format("MM/DD/YYYY");
        updateDoc(doc(db, "organizations", organization.id!), {
          expireDate: formattedExpireDate,
        });
        organization.expireDate = formattedExpireDate;
        if (isValid) status = "subscribed";
      }

      if (organization && (isPlatform("desktop") || isPlatform("mobileweb"))) {
        if (organization.expireDate) {
          const currentDate = moment(
            moment().format("MM/DD/YYYY"),
            "MM/DD/YYYY"
          );
          const expirationDate = moment(organization.expireDate, "MM/DD/YYYY");
          if (currentDate.isSameOrBefore(expirationDate)) {
            status = "subscribed";
          }
        }
      }

      if (user && status !== "subscribed") {
        const organizationDocRef = doc(db, `organizations/${user.uid}`);
        const organizationDoc = await getDoc(organizationDocRef);
        const organizationData = organizationDoc.data() as OrgData;
        const trialDays = organizationData.trialDays!;
        const today = moment();
        const createdDate = moment(organizationData.createdAt);
        setTrialPeriod(today.diff(createdDate, "days") <= trialDays);
        status =
          today.diff(createdDate, "days") <= trialDays
            ? "trial"
            : "unsubscribed";
      }

      setCurrentUser(user);
      setAccountStatus(status);
      setCurrentOrg(organization);
      setIsLoading(false);
    });
  }, []);

  const login = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      throw err;
    }
  };

  const signup = async (email: string, password: string, orgData: OrgData) => {
    try {
      const credentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await createOrganization(credentials.user.uid, orgData);
    } catch (err) {
      console.log(err);
    }
  };

  const recoverPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (err) {
      throw err;
    }
  };

  const logout = () => {
    auth.signOut();
  };
  const subscribe = async (sku: GlassfySku) => {
    const transaction = await Glassfy.purchaseSku({ sku });
    if (currentOrg && transaction.receiptValidated) {
      const { expireDate } = transaction.permissions.all[0];
      const formatedExpireDate = moment
        .unix(parseInt(expireDate))
        .format("MM/DD/YYYY");
      updateDoc(doc(db, "organizations"), currentOrg.id!, {
        expireDate: formatedExpireDate,
      });

      setAccountStatus("subscribed");
      setCurrentOrg({
        ...currentOrg,
        expireDate: formatedExpireDate,
      });
    }
  };

  const values = {
    login,
    signup,
    logout,
    subscribe,
    isLoading,
    currentOrg,
    currentUser,
    trialPeriod,
    appOfferings,
    accountStatus,
    recoverPassword,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const auth = useContext(AuthContext);

  if (!auth) {
    throw new Error("useAuth has to be used within <AuthContext.Provider>");
  }

  return auth;
};
