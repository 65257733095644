import {
  IonItem,
  IonList,
  IonModal,
  IonButton,
  IonContent,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import { useRef } from "react";

import useBooksService from "../../../../services/useBooksService";
import Header from "../../../../components/Header";
import { Book } from "../../../../models";

interface MoreModalProps {
  book: Book;
  isOpen: boolean;
  close: () => void;
  onExportBook: () => void;
}

export default function MoreModal({
  book,
  close,
  isOpen,
  onExportBook,
}: MoreModalProps): React.ReactElement {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const { updateBook } = useBooksService();
  const modal = useRef<HTMLIonModalElement>(null);
  return (
    <IonModal isOpen={isOpen} ref={modal} onWillDismiss={(e) => close()}>
      <Header
        title={"Additional Options"}
        withBorder={false}
        rightComponent={
          <IonButton
            size={"small"}
            fill={"clear"}
            className="ion-text-uppercase ion-no-margin ion-no-padding"
            onClick={() => modal.current?.dismiss()}
          >
            Close
          </IonButton>
        }
      />
      <IonContent>
        <IonList style={{ height: "100%" }}>
          <IonItem
            className={"MoreItem"}
            routerLink={`/books/${book.id}/edit-book`}
            onClick={() => modal.current?.dismiss()}
          >
            Edit Book
          </IonItem>
          <IonItem
            className={"MoreItem"}
            routerLink={`/books/${book.id}/batches`}
            onClick={() => {
              modal.current?.dismiss();
            }}
          >
            Batch Vaccines
          </IonItem>
          <IonItem
            className={"MoreItem"}
            routerLink={`/books/${book.id}/report`}
            onClick={() => modal.current?.dismiss()}
          >
            Summary Report
          </IonItem>
          <IonItem
            className={"MoreItem"}
            onClick={() => {
              onExportBook();
              modal.current?.dismiss();
            }}
          >
            Export Book
          </IonItem>

          <IonItem
            className={"MoreItem"}
            onClick={() => {
              let status = "archived";
              let title = "Are you sure you want to archive the book?";
              if (book.status === "archived") {
                status = "active";
                title = "Are you sure you want unarchive the book?";
              }

              presentAlert({
                header: title,
                buttons: [
                  {
                    text: "Cancel",
                    role: "cancel",
                    handler: () => {},
                  },
                  {
                    text: "Yes",
                    role: "confirm",
                    handler: async () => {
                      updateBook(book.id, { status });
                      router.push(`/books`, "back");
                      modal.current?.dismiss();
                    },
                  },
                ],
              });
            }}
          >
            {book.status === "archived" ? "Unarchive Book" : "Archive Book"}
          </IonItem>
          <IonItem
            className={"DeleteBook"}
            onClick={() =>
              presentAlert({
                header: "Are you sure you want to delete the book?",
                buttons: [
                  { text: "Cancel", role: "cancel", handler: () => {} },
                  {
                    text: "Yes",
                    role: "confirm",
                    handler: () => {
                      updateBook(book.id, { status: "deleted" });
                      router.push(`/books`, "back");
                      modal.current?.dismiss();
                    },
                  },
                ],
              })
            }
          >
            Delete Book
          </IonItem>
        </IonList>
      </IonContent>
    </IonModal>
  );
}
