import React from "react";
import {
  IonIcon,
  IonCard,
  IonPage,
  IonItem,
  IonTitle,
  IonLabel,
  IonInput,
  IonHeader,
  IonButton,
  IonButtons,
  IonContent,
  IonToolbar,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { chevronBackOutline } from "ionicons/icons";

import { useAuth } from "../../Auth/AuthProvider";

interface ForgotFormData {
  email: string;
}
export default function ForgotPassword() {
  const auth = useAuth();
  const router = useIonRouter();
  const [present] = useIonToast();

  const { register, handleSubmit } = useForm<ForgotFormData>({
    defaultValues: {
      email: "",
    },
  });

  const handleForgotPassword = async (data: ForgotFormData) => {
    try {
      await auth.recoverPassword(data.email);

      present({
        message: "Recovery link sent. Check your email.",
        duration: 1500,
        position: "top",
        color: "success",
        onDidDismiss: () => {
          router.goBack();
        },
      });
    } catch (err: any) {
      if (err.code === "auth/user-not-found") {
        present({
          message: "User does not exist.",
          duration: 1500,
          position: "top",
          color: "danger",
        });
      }
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton fill="clear" routerLink={`/login`}>
              <IonIcon icon={chevronBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Forgot Password</IonTitle>
          <IonButtons slot="end" />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <form onSubmit={handleSubmit(handleForgotPassword)}>
          <IonCard>
            <IonItem>
              <IonLabel>Email</IonLabel>
              <IonInput
                {...register("email", {
                  required: "This is a required field",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "invalid email address",
                  },
                })}
              />
            </IonItem>
          </IonCard>
          <IonCard>
            <IonButton fill="clear" expand="full" type="submit">
              Send Recover Email
            </IonButton>
          </IonCard>
        </form>
      </IonContent>
    </IonPage>
  );
}
